import {ONE_BYTE} from "./constants";

export const letterCase = (params) => {
  let result = '';

  const pattern = /([;:,-.\/ X])/;
  const array = params?.str?.split(pattern).filter(Boolean);
  const toLowerCase = params?.toLowerCase ?? 'Y';
  const skip = params?.skipArr ?? [
    'the',
    'a',
    'am',
    'an',
    'of',
    'and',
    'as',
    'but',
    'as',
    'if',
    'nor',
    'or',
    'so',
    'yet',
    'in',
    'on',
    'is',
    'are',
    'were',
    'was',
  ];

  array.forEach((v, k) => {
    if (skip.includes(v.toLowerCase())) {
      if (toLowerCase === 'Y') {
        result += v.toLowerCase();
      }
    } else {
      if (v[0] === '(') {
        result += v
          .toLowerCase()
          .replace(/^\(|(\b[a-z])/g, (match) => match.toUpperCase());
      } else {
        result += v
          .toLowerCase()
          .replace(/\b\w/g, (match) => match.toUpperCase());
      }
    }
  });

  return result;
};

export const lastWordLetterCase = (params) => {
  let {str} = params;

  if (!str) {
    return;
  }

  let words = str?.split(' ');

  if (words?.length > 1) {
    const lastWord = words?.pop();
    const modifiedLastWord = letterCase({str: lastWord});
    return words.join(' ') + " " + modifiedLastWord;
  }
  return str;
}


export const convertMbsToBytes = (mbs) => {
  return mbs * ONE_BYTE;
}

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  let item = window.localStorage.getItem(key);
  return JSON.parse(item);
};


export const setSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};
export const getSessionStorage = (key) => {
  let item = window.sessionStorage.getItem(key);
  return item;
};


export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}; 

export const getCurrentFormattedDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
export const disableCopyPaste = () => {
  ['copy', 'cut', 'paste', 'selectstart'].forEach((event) => {
    document.addEventListener(event, (e) => {
      e.preventDefault();
    });
  });
};

export const disableRightClick = () => {
  document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
};

  export const  isEmpty = (value) => {
  if (value === null || value === undefined) return true;
  if (typeof value === 'boolean' && value === false) return true;
  if (typeof value === 'number' && value === 0) return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === 'object' && Object.keys(value).length === 0) return true;
  return false;
}
export const generateRandomIntegerString = (length) => {
  let randomString = '';
  for (let i = 0; i < length; i++) {
    // Generate a random integer between 0 and 9
    randomString += Math.floor(Math.random() * 10);
  }
  return randomString;
}
