import {secondsToHms} from "../../../../helpers/TimeHelper";
import React, {useEffect, useState} from "react";
// Custom helper function to convert seconds to minutes and seconds
const secondsToMinutesAndSeconds = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};



export const useTimer = (params) => {
    const {stopRecording, questionTime, setQuestionTime, setShowAgainVideo, actualQuestionTime, status} = params;

    const [time, setTime] = useState(null);

    useEffect(() => {
         setTime(questionTime);
    }, [questionTime]);

    useEffect(() => {
        localStorage.setItem('vidQuesTimer', time?.toString());
        if (time === 0) {
            setQuestionTime(null);
            setShowAgainVideo(false);
            stopRecording();
            //  handleCompleteInterview();
        }
    }, [time]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime((prevTime) => Math.max(prevTime - 1, 0));
        }, 1000);


        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const getTimer = () => {
        if(status !== 'stopped') {
            if(time) {
                return secondsToMinutesAndSeconds(time);
            }
           return secondsToMinutesAndSeconds(actualQuestionTime);
        }
    };

  return {
    getTimer: getTimer
  }
}