import React, { useContext, useState, useEffect, useRef } from 'react';

import ReactPlayer from 'react-player';
import SortIcon from '@mui/icons-material/Sort';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles, useTheme } from '@mui/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Drawer,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  FormControl,
  IconButton,
} from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import { PlayIconTestResult } from '../../../assets/constants';
import { CareerCoachInterviewContext } from '../VideoInterview/CareerCoachInterviewContext';
import {
  ANSWER_SCORE_TEXT,
  ANSWER_SCORE_TEXT_COLOR,
} from '../../../helpers/constants';
import Payment from '../Payment';
import CompleteProfile from '../CompleteProfile';
import EmojiConfetti from './Confetti';
import { useLogPayment } from '../../../services/useLogPayment';
import Loader from '../../../common/Loader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDisableBackButton from '../../../helpers/hooks/useDisableBackButton';
import { deleteAllCookies } from '../../../helpers/CookieHelper';
import { isEmpty } from '../../../helpers/common';

const Styles = makeStyles((theme) => Style(theme));

export default function EvaluationMain() {
  const classes = Styles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  // const [language, setLanguage] = useState(0); // Temporary state for dropdown selection
  const [isPlaying, setIsPlaying] = useState(false);
  const [analysisVideo, setAnalysis] = useState(false);
  const [toggleVideo, setToggleVideo] = useState(false);
  const [analysisDecoded, setAnalysisDecoded] = useState('');
  const [toggleQuestion, setToggleQuestion] = useState(0); // Temporary state for dropdown selection
  const [toggleSelectLanguage, setToggleSelectLanguage] = useState(false);
  const { logPayment } = useLogPayment();
  const avAnsId = searchParams.get('aw');

  useEffect(() => {
    // Add a new history entry so that back button triggers popstate
    window.history.pushState(null, null, window.location.href);

    const handlePopState = (event) => {
      // Re-push the state to prevent back navigation
      window.location.reload();
    };

    // Listen for the popstate event, triggered by back button
    window.addEventListener('popstate', handlePopState);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const {
    videoAnalysis,
    paymentDrawer,
    setPaymentDrawer,
    completeProfileDrawer,
    setCompleteProfileDrawer,
    evaluationDrawer,
    setResultId,
    setLoader,
    videoData,
    loader,
    language,
    setLanguage,
    setQType,
    setCurrentQuestion,
    setQuestionDrawer,
    setActiveStep,
    releaseDevices,
    setStartInterview,
    setId,
    getResult,
    stream,
    simpleLoader,
    setSimpleLoader,
    isLoading,
    setProgress,
    setToggle,
  } = useContext(CareerCoachInterviewContext);

  if (avAnsId) {
    setSimpleLoader(true);
    setResultId(avAnsId);
  }

  useEffect(() => {
    releaseDevices();
  }, [stream]);

  useEffect(() => {
    const cachedAnsId = localStorage.getItem('answerId');
    if (
      !avAnsId &&
      (!cachedAnsId || cachedAnsId == 'undefined' || cachedAnsId == 'null')
    ) {
      if (Object.keys(videoData).length === 0) {
        localStorage.clear();
        sessionStorage.clear();
        deleteAllCookies();
        navigate('/career-coach');
      }
    }
  }, []);

  const handleReAttemp = () => {
    if (videoData?.answer_retry && Number(videoData?.answer_retry) >= 9) {
      setPaymentDrawer(true);
    } else {
      const careerCoachInterview = localStorage.getItem('careerCoachInterview');
      setProgress(0);
      setResultId(false);
      setStartInterview(true);
      setToggle(true);
      setCurrentQuestion(videoData?.question);
      setQType(videoData?.question?.type);
      setActiveStep(videoData?.question?.id);
      setQuestionDrawer(false);

      if (
        careerCoachInterview &&
        careerCoachInterview !== 'null' &&
        careerCoachInterview !== 'undefined'
      ) {
        setId(careerCoachInterview);
        navigate(`/career-coach/interview/${careerCoachInterview}`, {
          replace: true,
        });
      }
    }
  };
  const AnswerScoreText = (props) => {
    const { scoreText, color } = props;

    return (
      <span style={{ fontWeight: '700', color: `${color}` }}>{scoreText}.</span>
    );
  };

  const handleSelect = () => {
    setToggleSelectLanguage(true); // Open second drawer on select click
  };

  const handlePlay = (videoUrl) => {
    setIsPlaying(videoUrl);
    // openFullscreen();
    setToggleVideo(true);
  };
  const handleSelectLangauge = (type) => {
    setLanguage(type);
    setToggleSelectLanguage(false);
  };

  const highScore =
    videoAnalysis?.score_text?.toUpperCase() ===
    ANSWER_SCORE_TEXT.HIGHLY_RELEVANT;

  const mediumScore =
    videoAnalysis?.score_text?.toUpperCase() === ANSWER_SCORE_TEXT.MODERATE;

  const lowScore =
    videoAnalysis?.score_text?.toUpperCase() === ANSWER_SCORE_TEXT.NOT_MATCHING;

  var elem = document.getElementById('myvideo');

  /* Function to open fullscreen mode */
  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  return (
    <>
      {simpleLoader && (
        <Stack
          sx={{
            margin: '0 !important',
            position: 'fixed',
            top: '0',
            left: '0',
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            zIndex: '999',
          }}
        >
          <Loader />
        </Stack>
      )}
      {loader && (
        <Stack
          sx={{
            margin: '0 !important',
            position: 'fixed',
            top: '0',
            left: '0',
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            zIndex: '999',
          }}
        >
          <Loader
            textWithLoader
            text1="Analyzing your interview… just a bit more, we're wrapping it up"
            text2='Extracting voice, transcribing and translating.. almost done!'
            text3='Chill for a sec! This might take a few, depending on your internet speed'
          />
        </Stack>
      )}
      <Payment toggle={paymentDrawer} setPaymentToggle={setPaymentDrawer} />
      <CompleteProfile
        toggle={completeProfileDrawer && !loader}
        setProfileToggle={setCompleteProfileDrawer}
      />
      <Stack
        anchor='bottom'
        open={evaluationDrawer && !completeProfileDrawer}
        className={classes.evaluation__dialog}
        sx={{
          background: highScore
            ? 'linear-gradient(180deg, #CCEED8 27.16%, #FFF 39.94%)'
            : mediumScore
            ? 'linear-gradient(180deg, #f4ab00 27.16%, #FFF 39.94%)'
            : 'linear-gradient(180deg, #f4e1e5 27.16%, #FFF 39.94%)',
        }}
      >
        <Stack
          px={0.5}
          py={3}
          direction='column'
          height='100%'
          width='100%'
          justifyContent='flex-start'
          alignItems='center'
          color='primary.main'
          sx={{ overflowY: 'auto' }}
        >
          {false && <EmojiConfetti />}
          <Typography
            pb={1.2}
            variant='title30Bold'
            sx={{
              fontWeight: '700',

              color: highScore
                ? '#0aa06a'
                : mediumScore
                ? '#0b0b0b'
                : '#de1135',
            }}
          >
            {highScore
              ? 'Shabash'
              : mediumScore
              ? 'Mazah nhi aya!'
              : 'Bura haal!'}
          </Typography>
          <Stack
            width='100%'
            sx={{
              '& video, & .react-player__preview, & img': {
                borderRadius: '11px',
                width: '100%',
                objectFit: 'cover',
              },
            }}
            onClick={() => {
              setToggleVideo(true);
            }}
          >
            <Stack position='relative'>
              <img
                src={videoData?.thumbUrl}
                height='212px'
                width='100%'
                alt=''
              />
              <Stack
                sx={{
                  position: 'absolute',
                  bottom: '1rem',
                  left: '1rem',
                  borderRadius: '50px',
                  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
                  '& svg': {
                    '& path': {
                      opacity: '1',
                    },
                  },
                }}
              >
                <PlayIconTestResult />
              </Stack>
            </Stack>
          </Stack>
          <Stack width='100%' px={1.2}>
            <Stack
              pt={2}
              direction='row'
              width='100%'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='title30Bold' sx={{ fontWeight: '700' }}>
                Analysis
              </Typography>

              <FormControl
                sx={{ width: '113px' }}
                className={classes.dropdown__main}
                onClick={handleSelect}
              >
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={language}
                  sx={{
                    '& .MuiPopover-paper.MuiMenu-paper': {
                      boxShadow: 'none',
                    },
                  }}
                  disabled
                  IconComponent={() => <KeyboardArrowDownIcon />}
                >
                  <MenuItem value={'roman'}>Urdu</MenuItem>
                  <MenuItem value={'english'}>English</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack pb={'150px'} width='100%'>
              <Stack
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <Typography
                  mt={2}
                  mb={1}
                  px={1.5}
                  py={1}
                  variant='title12Bold'
                  sx={{
                    textAlign: 'center',
                    fontWeight: '500',
                    background: '#010103',
                    borderRadius: '50px',
                    color: '#fff',
                  }}
                  onClick={() => setToggleQuestion(true)}
                >
                  Question
                </Typography>

                <Typography
                  py={1.2}
                  variant='title14Bold'
                  sx={{
                    fontWeight: '400',
                    lineHeight: '24px',
                  }}
                >
                  {language === 'roman'
                    ? videoAnalysis?.analysis_urdu
                    : videoAnalysis?.analysis}
                  <br />
                  {/* <b>Summary</b> */}
                  <br />

                  {language === 'roman'
                    ? videoAnalysis?.summary_urdu
                    : videoAnalysis?.summary}
                </Typography>
                <Typography
                  mb={1}
                  py={1.2}
                  variant='title14Bold'
                  color='secondary.main'
                  sx={{
                    textDecoration: 'underline',
                    fontWeight: '500',
                    lineHeight: '24px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    logPayment({
                      action: 'suggestion',
                    });
                    setPaymentDrawer(true);
                  }}
                >
                  Suggestion to improve
                </Typography>

                <Stack
                  width='100%'
                  mb={5}
                  px={2}
                  sx={{
                    borderRadius: '11px',
                    background: '#ffffff',
                    boxShadow: '0px 0px 36px 0px rgba(0,0,0,0.10)',
                  }}
                >
                  <Stack
                    onClick={() => setAnalysis(!analysisVideo)}
                    py={2}
                    width='100%'
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography
                      variant='title16Bold'
                      sx={{ fontWeight: '600' }}
                    >
                      <SortIcon /> Transcript
                    </Typography>
                    {analysisVideo ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </Stack>
                  {analysisVideo && (
                    <Typography
                      pb={2.5}
                      variant='title14Bold'
                      sx={{
                        fontWeight: '400',
                        lineHeight: '24px',
                      }}
                    >
                      {language === 'roman' && videoData?.video_text_roman
                        ? videoData?.video_text_roman
                        : videoData?.video_text}
                    </Typography>
                  )}
                </Stack>
                {false && (
                  <Typography
                    variant={mediaquery1380 ? 'title14Bold' : 'titl16Bold'}
                    sx={{ fontWeight: '400', lineHeight: '22px' }}
                  >
                    <Typography
                      variant={mediaquery1380 ? 'title14Bold' : 'titl16Bold'}
                      sx={{ fontWeight: '700', lineHeight: '33px' }}
                    >
                      Analysis:{' '}
                    </Typography>
                    <br />
                    {videoAnalysis?.score_text?.toUpperCase() ===
                      ANSWER_SCORE_TEXT.HIGHLY_RELEVANT && (
                      <AnswerScoreText
                        scoreText={videoAnalysis?.score_text?.toUpperCase()}
                        color={ANSWER_SCORE_TEXT_COLOR.HIGHLY_RELEVANT}
                      />
                    )}{' '}
                    {videoAnalysis?.score_text?.toUpperCase() ===
                      ANSWER_SCORE_TEXT.MODERATE && (
                      <AnswerScoreText
                        scoreText={videoAnalysis?.score_text?.toUpperCase()}
                        color={ANSWER_SCORE_TEXT_COLOR.MODERATE}
                      />
                    )}
                    {videoAnalysis?.score_text?.toUpperCase() ===
                      ANSWER_SCORE_TEXT.NOT_MATCHING && (
                      <AnswerScoreText
                        scoreText={videoAnalysis?.score_text?.toUpperCase()}
                        color={ANSWER_SCORE_TEXT_COLOR.NOT_MATCHING}
                      />
                    )}{' '}
                    {videoAnalysis?.analysis}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack
            p={2}
            width='100%'
            direction='Column'
            alignItems='center'
            justifyContent='center'
            sx={{
              position: 'fixed',
              bottom: '0',
              left: 0,
              background: '#fff',
              boxShadow: '0px -1px 15px 0px rgba(0,0,0,0.17)',
            }}
          >
            <Button
              variant='outlined'
              title='Re-Attempt'
              height='56px'
              width='100%'
              fontSize='14px'
              fontWeight='400'
              borderRadius='8px'
              backgroundColor='#fff !important'
              borderStyle='1px solid #010103 !important'
              onClick={() => {
                logPayment({
                  action: 'reattempt',
                });
                handleReAttemp();
              }}
            />
            <Stack py={0.9} />
            <Button
              variant='contained'
              title='Try Another Question'
              height='56px'
              width='100%'
              fontSize='14px'
              fontWeight='400'
              borderRadius='8px'
              textColor='#fff'
              backgroundColor='#256ef4 !important'
              borderStyle='1px solid #256ef4 !important'
              onClick={() => {
                logPayment({
                  action: 'anotherquestion',
                });
                setPaymentDrawer(true);
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Drawer
        anchor='bottom'
        open={toggleQuestion}
        onClose={() => setToggleQuestion(false)}
        className={classes.question__dialog}
        sx={{
          '--Drawer-transitionDuration': toggleQuestion ? '1s' : '0.1s',
        }}
      >
        <CloseIcon
          sx={{
            position: 'absolute',
            right: '0.5rem',
            top: '0.5rem',
            color: '#010103',
          }}
          onClick={() => setToggleQuestion(false)}
        />
        <Typography p={3} variant='title20Bold' sx={{ fontWeight: '700' }}>
          {language === 'roman' && videoData?.question?.question_roman
            ? videoData?.question?.question_roman
            : videoData?.question?.question}
        </Typography>
      </Drawer>

      <Drawer
        anchor='bottom'
        open={toggleSelectLanguage}
        onClose={() => setToggleSelectLanguage(false)}
        className={classes.question__dialog}
        sx={{
          '--Drawer-transitionDuration': toggleSelectLanguage ? '1s' : '0.1s',
        }}
      >
        <CloseIcon
          sx={{
            position: 'absolute',
            right: '0.5rem',
            top: '0.5rem',
            color: '#010103',
            '& .MuiMenuItem-root': {
              paddingLeft: '20px',
            },
          }}
          onClick={() => setToggleSelectLanguage(false)}
        />
        <Typography
          p={3}
          pb={1}
          pl='20px'
          variant='title16Bold'
          sx={{ fontWeight: '500' }}
        >
          Select Language
        </Typography>

        <MenuItem value={'roman'} onClick={() => handleSelectLangauge('roman')}>
          Urdu
        </MenuItem>
        <MenuItem
          value={'english'}
          onClick={() => handleSelectLangauge('english')}
        >
          English
        </MenuItem>
      </Drawer>

      <Drawer
        anchor='bottom'
        open={toggleVideo}
        onClose={() => setToggleVideo(false)}
        className={classes.video__dialog}
        sx={{
          '--Drawer-transitionDuration': toggleVideo ? '1s' : '0.1s',
        }}
      >
        <IconButton
          onClick={() => setToggleVideo(false)}
          sx={{
            backgroundColor: '#fff',
            position: 'absolute',
            right: '0.5rem',
            top: '0.5rem',
            zIndex: '9',
            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
          }}
        >
          <CloseIcon
            sx={{
              color: '#010103',
            }}
          />
        </IconButton>
        <Stack
          width='100%'
          sx={{
            height: 'calc(100%)',
            '& video, & react-player_preview': {
              borderRadius: '0',
              width: '100%',
              objectFit: 'cover',
            },
          }}
        >
          <ReactPlayer
            url={videoData?.videoUrl}
            width='100%'
            height='100%'
            controls
            playsinline={false}
            id='myvideo'
            playing={videoData?.videoUrl && toggleVideo}
            paused={isPlaying === videoData?.videoUrl ? false : true}
            playIcon={<PlayIconTestResult />}
            // onReady={handleVideoReady}
            // light={<PlayCircleIcon />}|
            // onClickPreview={() => handlePlay(videoData?.videoUrl)}
          />
        </Stack>
      </Drawer>
    </>
  );
}
