import React, { useState, useContext, useRef } from "react";

import clsx from "clsx";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles, useTheme } from "@mui/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Autocomplete,
  Drawer,
  Grow,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  TextField,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

import Style from "./style";
import Button from "../../../common/Button";
import Textfield from "../../../common/Textfield";
import { useCities } from "../../../services/useCities";
import TermsConditionCheck from "../../../components/TermsConditionCheck";
import { headers, usefetch } from "../../../helpers/fetch";
import { CareerCoachInterviewContext } from "../VideoInterview/CareerCoachInterviewContext";
import { useAuthContext } from "../../../Auth/useAuthContext";
import { setLocalStorage } from "../../../helpers/common";

const Styles = makeStyles((theme) => Style(theme));

export default function CompleteProfile({ toggle, setProfileToggle }) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down("md"));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down("1380"));

  const skillInputRef = useRef(null);
  const skillsContainerRef = useRef(null);
  const { setPaymentDrawer } = useContext(CareerCoachInterviewContext);

  const [experience, setExperience] = useState("<1");
  const [selected, setSelected] = useState("student");
  const [loading, setLoading] = useState(false);
  const [skillsMain, setSkillsMain] = useState([]);
  const [respMessage, setRespMessage] = useState("");
  const { getCities, cities, isLoading } = useCities();
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [selectedCities, setSelectedCities] = useState(null);
  const [userExistsError, setUserExistsError] = useState("");
  const { user, refreshUserData, setUser } = useAuthContext();

  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    skill: "",
    skills: "",
    jobTitle: "",
    address: "",
    salary: "",
    gender: "",
    email: "",
    endDate: "",
    city_id: "",
    startDate: "",
    companyName: "",
    currentEmployer: "",
    universityName: "",
    academicDegree: "",
  });

  const [fieldVals, setFieldVals] = useState({
    name: "",
    hash: "",
    email: "",
    address: "",
    city: "",
    city_id: "",
    skill: "",
    gender: "",
    salary: "",
    mobile: "",
    skills: [],
    endDate: null,
    startDate: null,
    jobTitle: "",
    cvFileText: "",
    fullAddress: "",
    job_company: "",
    companyName: "",
    recent_degree: "",
    currentEmployer: "",
    experiences: [],
    degrees: [],
    totExp: "",
    calculatedDuration: "",
    recent_experience: "",
    cvLink: "",
    professionalSummary: "",
    universityName: "",
    academicDegree: "",
  });

  const handleCityChange = (event, value) => {
    setSelectedCities(value);
    setFieldVals({
      ...fieldVals,
      city: value?.city,
    });

    setFieldErrors((prevErrors) => ({ ...prevErrors, city: "" }));
  };

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFieldVals({
      ...fieldVals,
      [name]: value.trimStart(),
    });
    setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const KeyEnterSkill = (e) => {
    if (e.key === "Enter") {
      handleAddSkill(1);
    }
  };

  const handleAddSkill = () => {
    if (!fieldVals?.skill) {
      skillInputRef.current.focus(); // Focus on the skill input field if it's empty
      return;
    }

    if (fieldVals?.skill !== "") {
      const skillExists = skillsMain.some(
        (skill) => skill.name.toLowerCase() === fieldVals.skill.toLowerCase()
      );

      if (skillExists) {
        setRespMessage("Skill is already added.");
        return;
      }

      const newSkills = [
        ...skillsMain,
        {
          id: skillsMain.length + 1,
          name: fieldVals?.skill,
        },
      ];

      setSkillsMain(newSkills);
      setFieldVals({ ...fieldVals, skill: "" });
      setFieldErrors((prevErrors) => ({ ...prevErrors, skill: "" }));

      // Scroll to the newly added skill
      setTimeout(() => {
        if (skillsContainerRef.current) {
          skillsContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
        skillInputRef.current.focus(); // Keep focus on the skill input field
      }, 100);
    }
  };

  const handleDeleteSkill = (id) => {
    setSkillsMain(skillsMain.filter((obj) => obj.id !== id));
  };

  const handleUpdate = () => {
    let errors = {};

    // Validate name
    if (!fieldVals.name.trim()) {
      errors.name = "Name is required";
    }

    // Validate email
    if (!fieldVals.email.trim() && user.askForEmail  === "Y" ) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(fieldVals.email) && user.askForEmail  === "Y") {
      errors.email = "Invalid email format";
    }

    // Validate city
    if (!fieldVals.city) {
      errors.city_id = "City is required";
    }

    // Validate job title / academic degre
    if (selected === "student") {
      if (!fieldVals.academicDegree.trim()) {
        errors.academicDegree = "Academic Degree is required";
      }
    } else {
      if (!fieldVals.jobTitle.trim()) {
        errors.jobTitle = "Job Title is required";
      }
    }

    // Validate company / university
    if (selected === "student") {
      if (!fieldVals.universityName.trim()) {
        errors.universityName = "University is required";
      }
    } else {
      if (!fieldVals.companyName.trim()) {
        errors.companyName = "Company Name is required";
      }
    }

    // Validate skills
    if (skillsMain.length === 0) {
      errors.skills = "At least one skill is required";
    }

    // If there are errors, set them and return
    setFieldErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    let skills = [];
    skillsMain?.map((skill) => {
      skills.push(skill.name);
    });

    let data = Object.assign(fieldVals, {
      skills: skills,
      seekerType: selected,
      mobileEmail: user?.email,
      email: user.askForEmail === "N" ? user?.email : fieldVals.email,
    });

    if (selected === "student") {
      data = {
        ...data,
        recent_degree: {
          degree: fieldVals?.academicDegree,
          institute: fieldVals?.universityName,
        },
      };
    } else {
      data = { ...data, experience: experience };
    }
    setLoading(true);
    return usefetch(
      "applications/updateApplicationInfoForInterview",
      "post",
      headers(),
      {
        jid: sessionStorage.getItem("jid"),
        data: data,
        videoInterviewFlow: "Y",
      }
    ).then((resp) => {
      setLoading(false);
      if (resp?.code === "11") {
        setProfileToggle(false);
        if (resp?.userInfo) {
          setUser((user) => ({ ...user, ...resp?.userInfo }));
        }
      } else if (resp?.code === "01") {
        setPaymentDrawer(true);
        setProfileToggle(false);
      }

      refreshUserData();

      return resp;
    });
  };

  const handleChange = (selectedValue) => {
    setSelected(selectedValue);
  };

  const handleChangeExperience = (selectedValue) => {
    setExperience(selectedValue);
  };

  const minTimeIncrement = 1;

  const menuItems = [];
  for (let i = 1; i <= 15; i++) {
    const time = minTimeIncrement * i;
    menuItems.push(
      <MenuItem sx={{ borderRadius: "6px" }} value={time}>
        {time} year{time > 1 ? "s" : ""}
      </MenuItem>
    );
  }

  return (
    <Drawer
      anchor="bottom"
      open={toggle}
      className={classes.profile__dialog}
      sx={{
        "--Drawer-transitionDuration": toggle ? "1s" : "0.1s",
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiSnackbarContent-root": {
            background: "#010103",
            color: "#fff",
          },
        }}
        TransitionComponent={Grow}
        autoHideDuration={4000}
        open={respMessage?.length}
        message={respMessage}
        onClose={() => setRespMessage("")}
      />
      <Stack
        px={2}
        pb={18}
        pt={3}
        direction="column"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        color="primary.main"
      >
        <Typography pb={3} variant="title30Bold" sx={{ fontWeight: "700" }}>
          Please complete your profile to see results
        </Typography>

        <Stack
          sx={{
            "& .label__main": {
              fontSize: "18px",
              fontWeight: "400",
              [theme.breakpoints.down("md")]: {
                fontSize: "16px",
                whiteSpace: "wrap",
              },
            },
            "& .control-width": {
              width: "100%",
            },
            "& .textfield__global": {
              marginTop: "8px",
              marginBottom: "4px",
              "& .MuiInputBase-root": {
                height: "60px",
                borderRadius: "11px",
                border: "none",
                [theme.breakpoints.down("md")]: {
                  height: "52px",
                },
                "& input": {
                  fontSize: "18px",
                  fontWeight: "400",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "16px",
                    paddingTop: 0,
                    paddingLeft: ".7rem",
                    paddingRight: ".7rem",
                    paddingBottom: 0,
                  },
                },
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #d3d3d3 !important", // Default border color
                },
                "&:hover fieldset": {
                  border: "1px solid #010103 !important", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #010103 !important", // Border color on focus
                },
              },
              "& .MuiFormHelperText-root": {
                color: "#ec7376",
                fontSize: "12px",
                fontWeight: "400",
                marginLeft: "0",
              },
            },
          }}
          flexWrap="wrap"
          direction="row"
        >
          <FormControl
            sx={{ mt: 4, width: "100%" }}
            className={clsx("textfield__global", classes.marginBottom)}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected}
              onChange={(e) => handleChange(e.target.value)}
              sx={{
                "& .MuiPopover-paper.MuiMenu-paper": {
                  boxShadow: "none",
                },
              }}
              IconComponent={() => <KeyboardArrowDownIcon />}
              MenuProps={{
                sx: {
                  zIndex: "1990 !important",
                },
                PaperProps: {
                  sx: {
                    zIndex: "1990 !important",
                  },
                },
              }}
            >
              <MenuItem
                sx={{ fontSize: "18px", fontWeight: "400" }}
                value="student"
              >
                Student
              </MenuItem>
              <MenuItem
                sx={{ fontSize: "18px", fontWeight: "400" }}
                value="employed"
              >
                Employed
              </MenuItem>
              <MenuItem
                sx={{ fontSize: "18px", fontWeight: "400" }}
                value="not-employed"
              >
                Not Employed
              </MenuItem>
            </Select>
            <FormHelperText>{fieldErrors?.role} &nbsp;</FormHelperText>
          </FormControl>

          <Textfield
            placeholder="Full Name"
            error={!!fieldErrors.name}
            value={fieldVals?.name}
            helperText={fieldErrors.name || ""}
            onChange={(e) => handleChanges(e)}
            name="name"
            label="Full Name"
            required
          />
          {user.askForEmail === "Y" && (
            <Textfield
              placeholder="Email Address"
              error={!!fieldErrors.email}
              helperText={fieldErrors.email || ""}
              value={fieldVals?.email}
              onChange={(e) => handleChanges(e)}
              name="email"
              label="Email Address"
              required
            />
          )}

          <Typography
            width="100%"
            variant="title20Bold"
            className="label__main"
          >
            City
          </Typography>
          <Stack
            width="100%"
            className={clsx(
              classes.subordinates__count,
              classes.cities__stack,
              selectedCities?.length >= 8 && classes.cities__field__none
            )}
            sx={{
              "& .textfield__global": {
                "& .MuiInputBase-root": {
                  paddingLeft: "0 !important",
                  borderRadius: "11px",
                  "& input": {
                    padding: smMediaquery
                      ? ".2rem .7rem !important"
                      : ".2rem 1.5rem !important",
                  },
                  "& fieldset": {
                    borderColor: "#c8c8c8!important",
                    borderWidth: "1px !important",
                  },
                },
              },
              "& .MuiFormHelperText-root": {
                color: "#ec7376",
                fontSize: "12px",
              },
            }}
          >
            <Autocomplete
              autoHighlight={true}
              id="tags-standard"
              options={cities}
              getOptionLabel={(option) => option?.city}
              getOptionDisabled={false}
              onChange={handleCityChange}
              disableClearable
              value={selectedCities}
              popupIcon={false}
              renderOption={(props, option) => (
                <li {...props}>{isLoading ? "Loading" : option?.city}</li>
              )}
              renderInput={(params) => (
                <TextField
                  className="textfield__global"
                  {...params}
                  variant="outlined"
                  name="city"
                  onEnter={(e) => {
                    console.log(e.target.value);
                  }}
                  onChange={(e) => {
                    getCities(e.target.value);
                    handleChanges(e);
                  }}
                  helperText={fieldErrors?.city_id || " "}
                  placeholder={selectedCities?.length >= 1 ? "" : "Enter City"}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                    sx: {
                      "&::placeholder": {
                        color: "#d6d6d6",
                        opacity: 1,
                      },
                    },
                  }}
                  // InputProps={{
                  //   endAdornment: (
                  //     <SpecificEndAdornment
                  //       nameSpecific='city_id'
                  //       valueSpecific={fieldVals?.city_id}
                  //     />
                  //   ),
                  // }}
                />
              )}
              PopperProps={{
                sx: {
                  zIndex: "1990 !important",
                },
              }}
            />
          </Stack>

          {selected === "student" ? (
            <Textfield
              error={!!fieldErrors.academicDegree}
              value={fieldVals?.acedemicDegree}
              helperText={fieldErrors.academicDegree || ""}
              onChange={handleChanges}
              name="academicDegree"
              label={"Academic Degree"}
              required
            />
          ) : (
            <Textfield
              placeholder="Job Title"
              error={!!fieldErrors.jobTitle}
              value={fieldVals?.jobTitle}
              helperText={fieldErrors.jobTitle || ""}
              onChange={handleChanges}
              name="jobTitle"
              label={"Job Title"}
              required
            />
          )}

          {selected === "student" ? (
            <Textfield
              error={!!fieldErrors.universityName}
              value={fieldVals?.universityName}
              helperText={fieldErrors.universityName || ""}
              onChange={handleChanges}
              name="universityName"
              label={"University"}
              required
            />
          ) : (
            <Textfield
              placeholder="Company Name"
              error={!!fieldErrors.companyName}
              value={fieldVals?.companyName}
              helperText={fieldErrors.companyName || ""}
              onChange={handleChanges}
              name="companyName"
              label={"Company Name"}
              required
            />
          )}

          {selected !== "student" && (
            <>
              <Typography
                width="100%"
                variant="title20Bold"
                className="label__main"
              >
                Years of Experience
              </Typography>
              <FormControl
                sx={{ mt: 4, width: "100%" }}
                className={clsx("textfield__global", classes.marginBottom)}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={experience}
                  onChange={(e) => handleChangeExperience(e.target.value)}
                  sx={{
                    "& .MuiPopover-paper.MuiMenu-paper": {
                      boxShadow: "none",
                    },
                  }}
                  IconComponent={() => <KeyboardArrowDownIcon />}
                  MenuProps={{
                    sx: {
                      zIndex: "1990 !important",
                    },
                    PaperProps: {
                      sx: {
                        zIndex: "1990 !important",
                      },
                    },
                  }}
                >
                  <MenuItem value={"<1"}>Less than 1 year</MenuItem>

                  {menuItems}

                  <MenuItem value={`15+`}>15+ years</MenuItem>
                </Select>
                <FormHelperText>{fieldErrors?.role} &nbsp;</FormHelperText>
              </FormControl>
            </>
          )}

          {/* Skills Input */}
          <Stack width="100%">
            <Textfield
              placeholder="Add Skill"
              margin="dense"
              variant="outlined"
              name="skill"
              isFromPostNewJob
              inputRef={skillInputRef}
              onEnter={KeyEnterSkill}
              value={fieldVals?.skill}
              onChange={(e) => handleChanges(e)}
              helperText={fieldErrors.skills}
              label="Add Skill"
              endAdornment={
                fieldVals?.skill ? (
                  <AddIcon
                    sx={{ color: "#010103", cursor: "pointer" }}
                    onClick={() => handleAddSkill(1)}
                  />
                ) : (
                  <AddIcon sx={{ color: "#a0a0a0" }} />
                )
              }
            />

            {!!skillsMain.length && (
              <Stack direction="row" flexWrap="wrap" ref={skillsContainerRef}>
                {skillsMain.map((k) => (
                  <Stack
                    key={k?.id}
                    sx={{
                      borderRadius: "13px",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginRight: ".5rem",
                      marginBottom: "0",
                      marginTop: "0.5rem",
                      background: "#ededed",
                      width: "max-content",
                      padding: "14px 16px 14px 16px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "400",
                      }}
                      variant={smMediaquery ? "title12Bold" : "title14Bold"}
                      color="primary.main"
                    >
                      {k?.name}
                      <CloseRoundedIcon
                        className={classes.close__icon}
                        onClick={() => handleDeleteSkill(k?.id)}
                      />{" "}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        p={2}
        spacing={1.2}
        width="100%"
        sx={{
          position: "fixed",
          bottom: "0",
          left: 0,
          background: "#fff",
          boxShadow: "0px -1px 15px 0px rgba(0,0,0,0.17)",
        }}
      >
        <Button
          height="38px"
          width="100%"
          fontSize="14px"
          fontWeight="400"
          borderRadius="60px"
          className={clsx("MuiButton-containedSecondary", classes.fullwidthBtn)}
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={() => handleAddSkill(1)}
        >
          Add Skill
        </Button>
        {skillsMain.length ? (
          <Button
            height="56px"
            width="100%"
            fontSize="14px"
            fontWeight="400"
            borderRadius="8px"
            className={clsx(
              "MuiButton-containedSecondary",
              classes.fullwidthBtn
            )}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleUpdate}
          >
            Continue
          </Button>
        ) : null}
      </Stack>
    </Drawer>
  );
}
