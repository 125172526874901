const Style = (theme) => ({
  question__dialog: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: 'max-content',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px 10px 0 0',
      boxShadow: 'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  video__dialog: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: '100vh',
      position: 'relative',
      padding: '0rem',
      borderRadius: '10px 10px 0 0',
      boxShadow: 'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  evaluation__dialog: {
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: '100vh',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '0px',
      boxShadow: 'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  dropdown__main: {
    width: '100%',
    '& .MuiInputBase-root': {
      borderRadius: '50px',
      paddingRight: '9px',
      height: '42px',
      background: '#fff !important',
      '& fieldset': {
        borderWidth: '1px !important',
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: '9px',
        pointerEvents: 'none !important',
      },
      '& .MuiSelect-select': {
        textAlign: 'left',
        paddingRight: '13px !important',
        fontSize: '14px',
        fontWeight: '400',
      },
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#010103',
      '& .MuiSelect-select': {
        color: '#010103',
        WebkitTextFillColor: '#010103',
      },
    },
    '& fieldset': {
      borderColor: '#eaeaea !important',
    },
  },
});
export default Style;
