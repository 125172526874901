import React, { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Divider,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';

import VideoSection from './Components/VideoSection';
import StepperStack from './Components/StepperStack';
import SuccessSubmit from '../../../components/TestBuilder/AttemptTest/SuccessSubmit';
import Loader from '../../../common/Loader';
import Permission from './Components/Permissions';
import { CareerCoachInterviewContext } from './CareerCoachInterviewContext';
import { useTheme } from '@emotion/react';
import { useDisableUserActions } from '../../../helpers/hooks/useDisableRightClick';
import SelectQuestionsDialog from '../SelectQuestionDialog';
import Payment from '../Payment';
import CompleteProfile from '../CompleteProfile';
import { useParams } from 'react-router-dom';
import useDisableBackButton from '../../../helpers/hooks/useDisableBackButton';
import IOSPermission from './Components/IOSPermissions';

export default function CareerCoachInterview() {
  const {
    simpleLoader,
    isLoading,
    isSavingAnswers,
    hasPermission,
    setHasPermission,
    isVideoInterviewCompleted,
    isGettingQuestions,
    jobTitle,
    questionDrawer,
    setQuestionDrawer,
    releaseDevices,
    loader,
    setId,
    stream,
    progressBar,
    progress,
  } = useContext(CareerCoachInterviewContext);
  const theme = useTheme();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      setId(id);
    }
  }, [id]);

  //  useDisableBackButton();

  useEffect(() => {
    return () => releaseDevices();
  }, [stream]);

  useEffect(() => {
    console.log('simpleLoader', simpleLoader);
    console.log('loader', loader);
  }, [simpleLoader, loader]);

  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  const isSafari = () => {
    const ua = navigator.userAgent;
    return ua.includes('Safari') && !ua.includes('Chrome');
  };

  console.log(navigator?.userAgent);
  return (
    <>
      {isLoading && (
        <Loader
          textWithLoader
          text1='We are fetching your test. Please hold on!'
          text2='We are still fetching your test. Please hold on!'
          text3='Please do not close this browser window while we are fetching your test!'
        />
      )}
      {loader ? (
        <Loader
          textWithLoader
          text1="Analyzing your interview… just a bit more, we're wrapping it up"
          text2='Extracting voice, transcribing and translating.. almost done!'
          text3='Chill for a sec! This might take a few, depending on your internet speed'
        />
      ) : simpleLoader ? (
        <Loader />
      ) : questionDrawer ? (
        <SelectQuestionsDialog
          toggle={questionDrawer}
          setToggle={setQuestionDrawer}
        />
      ) : isSavingAnswers ? (
        <Loader
          textWithLoader
          text1='We are saving your answer. Please hold on!'
          text2='We are still saving your answer. Please hold on!'
          text3='Please do not close this browser window while we are saving your answer'
          progressBar={progressBar}
          progress={progress}
        />
      ) : (
        <>
          {!hasPermission ? (
            isSafari() ? (
              <IOSPermission
                toggle={!hasPermission}
                setToggle={setHasPermission}
              />
            ) : (
              <Permission
                toggle={!hasPermission}
                setToggle={setHasPermission}
              />
            )
          ) : (
            <>
              <Stack direction='row' height='100vh'>
                <Stack
                  color='primary.light'
                  p={4.5}
                  sx={{
                    backgroundColor: '#010103',
                    width: '35%',
                    height: '100%',
                    display: { sm: 'none', xs: 'none', md: 'flex' },
                  }}
                >
                  <Typography variant='mainInput'>
                    {isGettingQuestions ? (
                      <Skeleton
                        width='200px'
                        sx={{ backgroundColor: 'rgb(255, 255, 255 , .29)' }}
                      />
                    ) : jobTitle ? (
                      jobTitle
                    ) : (
                      ''
                    )}
                  </Typography>
                  <Divider sx={{ backgroundColor: '#fff', my: 5 }} />
                  <Typography
                    pb={3.75}
                    variant='title20Bold'
                    sx={{ fontWeight: '600' }}
                  >
                    Question
                  </Typography>
                  <StepperStack />
                </Stack>
                <Stack sx={{ width: smMediaquery ? '100%' : '65%' }}>
                  <VideoSection />
                </Stack>
              </Stack>
            </>
          )}
        </>
      )}
    </>
  );
}
