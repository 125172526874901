import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGetVideoInterviewQuestions } from "../../../services/useGetVideoInterviewQuestions";
import { useCompleteVideoInterview } from "../../../services/useCompleteVideoInterview";
import { makeStyles, useTheme } from "@mui/styles";
import Style from "./style";
import { useClearVideoInterview } from "../../../services/useClearVideoInterview";
// import { useReactMediaRecorder } from "react-media-recorder";
import { useWebRTCRecorder } from "../../../helpers/hooks/useWebRTCRecorder";
import {
  convertMbsToBytes,
  generateRandomIntegerString,
  getCurrentFormattedDate,
  getLocalStorage,
  isEmpty,
  setLocalStorage,
} from "../../../helpers/common";
import {
  defaultHeaders,
  fileUploadHeaders,
  fileUploadTokenHeaders,
  headers,
  usefetch,
  usefileUploader,
} from "../../../helpers/fetch";
import { useDisableUserActions } from "../../../helpers/hooks/useDisableRightClick";
import { useAuthContext } from "../../../Auth/useAuthContext";
import { useGlobalLoader } from "../../../App";
import {SITE_AT} from "../../../helpers/utils";
import {APP_ENV} from "../../../helpers/constants";
import {useGetInterviews} from "../services/useGetInterviews";

const Styles = makeStyles((theme) => Style(theme));
export const useCareerCoachInterview = () => {
  // useDisableUserActions();
  const classes = Styles();
  const theme = useTheme();

  const navigate = useNavigate();
  const [simpleLoader, setSimpleLoader] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [startInterview, setStartInterview] = useState(0);

  const [paymentDrawer, setPaymentDrawer] = useState(false);
  const [completeProfileDrawer, setCompleteProfileDrawer] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [videoBlobUrl, setVideoBlobUrl] = useState(null);
  const [resetRecorder, setResetRecorder] = useState(false);
  const [isSavingAnswers, setIsSavingAnswers] = useState(false);
  const { completeVideoInterview, isCompleted } = useCompleteVideoInterview();
  const { user } = useAuthContext();
  const [id, setId] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [startRecord, setStartRecord] = useState(false);
  const [questionTime, setQuestionTime] = useState(null);
  const [showAgainVideo, setShowAgainVideo] = useState(false);
  const { clearVideoInterview, isClearingInterview } = useClearVideoInterview();
  const [videoData, setVideoData] = useState({});
  const [stream, setStream] = useState(false);
  const [questionDrawer, setQuestionDrawer] = useState(true);
  const [evaluationDrawer, setEvaluationDrawer] = useState(false);
  const [videoAnalysis, setVideoAnalysis] = useState(null);
  const [answerId, setAnswerId] = useState(false);
  const [resultId, setResultId] = useState(false);

  const [loader, setLoader] = useState(false);
  const [selectedQType, setQType] = useState("");
  const [language, setLanguage] = useState("english");
  const [selectedQuestions, setTQuestion] = useState(null);

  // State to track the progress percentage
  const [progress, setProgress] = useState(0);
  const [progressBar, setProgressBar] = useState(false);

  const [chunks, setChunks] = useState([]);
  const [chunkSliceSize, setChunkSliceSize] = useState(5000);
  const [answerRd, setAnswerRd] = useState(null);
  const [currentChunkUploded, setCurrentChunkUploded] = useState(0);
  const {getInterviews, interviews, answerTestCode}  =  useGetInterviews();

  const {
    getVideoInterviewQuestions,
    jobTitle,
    reRecord,
    setRerecord,
    setCountdown,
    isVideoInterviewCompleted,
    videoInterviewQuestions,
    setIsVideoInterviewCompleted,
    isGettingQuestions,
    isLoading,
    setIsLoading,
    countdown,
  } = useGetVideoInterviewQuestions();

  const handleDataAvailable = (blob) => {
    if (blob) {
      console.log("blob", blob);

      setChunks((prevChunks) => [...prevChunks, blob]);

      let tot = getLocalStorage("totChunk");
      setLocalStorage("totChunk", parseInt(tot) + 1);
      handleSaveQuestion(blob, "Y");
    }
  };

  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  let sliceSize = 3000;
  if (isIOS || isSafari) {
    sliceSize = 2000;
  }

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    getMediaStream,
    clearBlobUrl,
    error,
    mediaFormat,
    mediaStreamRef,
  } = useWebRTCRecorder({
    video: true,
    stopStreamsOnStop: true,
    facingMode: { exact: "user" },
    onDataAvailable: handleDataAvailable,
    timeSlice: sliceSize, // Record in 10-second chunks
  });
  const videoRef = useRef();

  useEffect(() => {
    const cachedAnsId = localStorage.getItem("answerId");
    if (cachedAnsId && cachedAnsId !== "undefined" && cachedAnsId !== "null") {
      setLoader(true);
      usefetch("videoInterview/ansStatus", "post", defaultHeaders(), {
        id: cachedAnsId,
      }).then(function (resp) {
        setLoader(false);
        if (resp?.data?.question) {
          setVideoAnalysis(resp?.data?.analysis);
          setVideoData(resp?.data);
          if (resp?.data?.countdown) {
            setCountdown(resp?.data?.countdown);
          }
          if (resp?.data?.countdown) {
            setRerecord(resp?.data?.reRecord);
          }

          if(!isEmpty(resp?.data?.ans_lang)) {
            setLanguage(resp?.data?.ans_lang);
          }

          if (
            resp?.data?.answer_retry &&
            Number(resp?.data?.answer_retry) >= 9
          ) {
            setPaymentDrawer(true);
          } else {
            setResultId(false);
            setCurrentQuestion(resp?.data?.question);
            setActiveStep(resp?.data?.question.id);
            setQType(resp?.data?.question?.type);
            setQuestionDrawer(false);
          }
          setLoader(false);
          releaseDevices();
          if(SITE_AT ===  APP_ENV.LIVE || SITE_AT ===  APP_ENV.DEMO) {
            navigate("/career-coach/evaluation", { replace: true });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (previewStream && videoRef.current) {
      videoRef.current.srcObject = previewStream;
    }
    if (previewStream) {
      setHasPermission(true);
      setIsLoading(false);
      gotUserMedia(previewStream);
    } else {
      setHasPermission(false);
      setIsLoading(false);
    }
  }, [previewStream]);

  useEffect(() => {
    if (error === "recorder_error") {
      console.error(
        "from Error MediaRecorder Not supported on this browser try on an other device!"
      );
      usefetch("videoInterview/logIssues", "post", defaultHeaders(), {
        testCode: id,
      }).then(function (resp) {});
      setSimpleLoader(true);
      alert(
        "MediaRecorder Not supported on this browser try on an other device!"
      );
    }
    // if (error) {
    //   alert(error);
    // }
  }, [error]);

  useEffect(() => {
    if (selectedQType && id) {
      if (isEmpty(currentQuestion?.question)) {
        getVideoInterviewQuestions({ testCode: id, type: selectedQType });
      }
      checkPermissions();
    }
  }, [selectedQType]);

  useEffect(() => {
    if (videoInterviewQuestions.length > 0) {
      setActiveStep(videoInterviewQuestions[0].id);
      setStartInterview(true);
    }
  }, [videoInterviewQuestions]);

  useEffect(() => {
    setsCurrentQuestion();
  }, [activeStep]);

  useEffect(() => {
    setIsLoading(false);
  }, [currentQuestion]);

  async function checkPermissions() {
    if (window.location.pathname === "/career-coach/evaluation") {
      return;
    }
    try {
      await getMediaStream();
      return () => releaseDevices();
    } catch (err) {
      setHasPermission(false);
      setIsLoading(false);

      // Handle specific errors
      if (
        err === "no_specified_media_found" ||
        err === "DevicesNotFoundError"
      ) {
        console.error("No devices found.");
      } else if (err === "permission_denied") {
        console.error("Permissions denied for accessing media devices.");
      } else {
        console.error("An unexpected error occurred.");
      }
    }
  }

  // Function to handle the media stream (e.g., displaying it in a video element)
  function gotUserMedia(stream) {
    const videoElement = document.querySelector("video");
    if (videoElement) {
      videoElement.srcObject = stream;
      videoElement.onloadedmetadata = function (e) {
        videoElement.play();
      };
    } else {
      console.error("No video element found to display the stream.");
    }
  }

  // Example mediaConstraints object (you should define this based on your requirements)
  const mediaConstraints = {
    video: { width: 1280, height: 720 }, // Example: request 720p video
    audio: true, // Request audio input
  };

  useEffect(() => {
    if (startInterview && !hasPermission) {
      checkPermissions();
    }
    return () => {
      releaseDevices();
    };
  }, []);

  const streamCamVideo = async () => {
    if (window.location.pathname === "/career-coach/evaluation") {
      return;
    }
    await getMediaStream();
  };

  useEffect(() => {
    if (startInterview) {
      streamCamVideo();
    }
  }, [startInterview]);

  const detectSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    const isSafari = ua.includes("safari") && !ua.includes("chrome");
    const isIOSSafari = /iP(ad|hone|od).+Version\/[\d.]+.*Safari/i.test(ua);
    return isSafari || isIOSSafari;
  };

  const releaseDevices = () => {
    if (previewStream) {
      previewStream.getTracks().forEach((track) => {
        if (track.readyState == "live") {
          track.stop();
        }
      });
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    if (previewStream) {
      // Additional step: Release microphone resources explicitly
      previewStream.getAudioTracks().forEach((track) => {
        if (track.readyState == "live") {
          track.stop();
        }
      });
    }
    // Ensure to clear any video elements using the stream (especially for Safari)

    // Optional: Revoke object URLs if created for blobs
    if (mediaBlobUrl) {
      URL.revokeObjectURL(mediaBlobUrl);
    }
  };

  useEffect(() => {
    if (isVideoInterviewCompleted) {
      releaseDevices();
      navigate("/career-coach/evaluation", { replace: true });
    }
  }, [isVideoInterviewCompleted]);

  useEffect(() => {
    setVideoBlobUrl(mediaBlobUrl);
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (resetRecorder) {
      clearBlobUrl();
      streamCamVideo();
    }
  }, [resetRecorder]);

  const handleStopRecording = () => {
    setQuestionTime(null);
    stopRecording();
    setSimpleLoader(true);
    setTimeout(() => {
      setSimpleLoader(false);
    }, 400);
    setShowAgainVideo(false);
    releaseDevices();
  };

  const handleNext = () => {
    if (!videoBlobUrl) {
      alert("You must record your video");
      return;
    }
    // Retrieve the initial values of total and uploaded chunks
    let totChunk = parseInt(getLocalStorage("totChunk"));
    let uploadedChunks = parseInt(getLocalStorage("uploadedChunks"));

    // Check if totChunk and uploadedChunks are equal
    const checkChunks = () => {
      totChunk = parseInt(getLocalStorage("totChunk"));
      uploadedChunks = parseInt(getLocalStorage("uploadedChunks"));
      let progPercent =0;
      if (uploadedChunks > 0) {
        progPercent = Math.ceil((uploadedChunks / totChunk) * 100);
      }
      setProgressBar(true);
      setProgress(progPercent);
      if (totChunk && uploadedChunks && totChunk === uploadedChunks) {
        clearInterval(intervalId); // Clear the interval when chunks are equal
        handleSaveQuestion("", "N"); // Trigger save question
        setProgress(0);
        setProgressBar(false);
      }
    };

    // Set an interval to check the chunks every 1 second
    const intervalId = setInterval(checkChunks, 1000);
    setVideoBlobUrl(null);
    setIsSavingAnswers(true);
  };

  let isUploading = false;

  const handleSaveQuestion = async (mediaBlobUrl, beforeSubmit = "") => {
    let videoBlob = null;
    const formData = new FormData();
    formData.append("testCode", id);
    formData.append("questionId", currentQuestion?.id);
    formData.append("answerRd", answerRd);

    if (mediaBlobUrl) {
      videoBlob = mediaBlobUrl;
      const type = mediaFormat.split(";")[0];
      const ext = type.split("/")[1];
      const videoFile = new File([videoBlob], "videoFile." + ext, {
        type: mediaFormat,
      });

      const chunkSize = convertMbsToBytes(4);

      const videoSize = videoFile?.size;
      let totChunks = getLocalStorage("totChunks");
      let uploadedChunks = getLocalStorage("uploadedChunks");

      const chunk = videoFile;
      formData.append("file", chunk);
      formData.append("ext", ext);
      formData.append("totChunks", totChunks);
      formData.append("chunkSize", videoSize);
      formData.append("uploadedChunks", uploadedChunks);
      formData.append("videoSize", videoSize);
      formData.append("ansLang", language);
      formData.append("currentTime", getCurrentFormattedDate());
    }

    isUploading = true;
    let resp = null;
    if (mediaBlobUrl) {
      resp = await usefileUploader(
        "videoInterview/uploadChunkGuru",
        "post",
        fileUploadTokenHeaders(),
        formData
      );
    } else {
      resp = await usefetch(
        "videoInterview/uploadChunkGuru",
        "post",
        headers(),
        {
          testCode: id,
          questionId: currentQuestion?.id,
          answerRd: answerRd,
          ansLang: language,
        }
      );
    }

    if (resp?.data?.answerId) {
      let upload = getLocalStorage("uploadedChunks");
      setLocalStorage("uploadedChunks", parseInt(upload) + 1);
      if (mediaBlobUrl) {
        setCurrentChunkUploded((prev) => prev + 1);
      }

      setAnswerId(resp?.data?.answerId);
      localStorage.setItem("answerId", resp?.data?.answerId);
      if (beforeSubmit === "N") {
        setProgressBar(false);
        setResultId(resp?.data?.answerId);
        isUploading = false;
        var mediaStream = document.querySelector("video")?.srcObject;
        releaseDevices(mediaStream);
        setIsVideoInterviewCompleted(true);

        //complete interview api call remaining
        completeVideoInterview({ testCode: id }).then((resp) => {});
        setIsSavingAnswers(false);
        setIsLoading(false);
        setLoader(true);
      }
    }
    // }
  };

  const setsCurrentQuestion = () => {
    if (!activeStep) return false;
    const cQ = videoInterviewQuestions.find(
      (question) => question.id === activeStep
    );
    if (cQ) {
      setCurrentQuestion(cQ);
    }
    setToggle(true);
  };

  useEffect(() => {
    if (toggle) {
      setAnswerRd(generateRandomIntegerString(7));
      setLocalStorage("uploadedChunks", 0);
      setLocalStorage("totChunk", 0);
    }
  }, [toggle]);

  const handleReset = () => {
    setActiveStep(0);
  };

  const getResult = async () => {
    if (resultId) {
      return usefetch("videoInterview/ansStatus", "post", defaultHeaders(), {
        id: resultId,
      }).then(function (resp) {
        if (resp?.data?.analysis_status === "done") {
          setSimpleLoader(false);
          setVideoAnalysis(resp?.data?.analysis);
          setVideoData(resp?.data);
          if (resp?.data?.countdown) {
            setCountdown(resp?.data?.countdown);
          }
          if (resp?.data?.countdown) {
            setRerecord(resp?.data?.reRecord);
          }
          if(!isEmpty(resp?.data?.ans_lang)) {
            setLanguage(resp?.data?.ans_lang);
          }
          if (!user?.seekerType) {
            setTimeout(() => {
              setCompleteProfileDrawer(true);
            }, 5000);
          }
          setCurrentQuestion(resp?.data?.question);
          setActiveStep(resp?.data?.question.id);
          setQType(resp?.data?.question?.type);
          setQuestionDrawer(false);
          releaseDevices();
          navigate("/career-coach/evaluation", { replace: true });
        }
        return resp;
      });
    }
  };

  useEffect(() => {
    let timeInterval = null;
    if (resultId) {
      timeInterval = setInterval(() => {
        getResult().then((resp) => {
          if (resp?.data?.analysis_status === "done") {
            setLoader(false);
            clearInterval(timeInterval);
          }
        });
      }, 5000);
    }
    return () => clearInterval(timeInterval);
  }, [resultId]);

  return {
    classes,
    theme,
    getVideoInterviewQuestions,
    jobTitle,
    reRecord,
    isVideoInterviewCompleted,
    videoInterviewQuestions,
    setIsVideoInterviewCompleted,
    isGettingQuestions,
    isLoading,
    isSavingAnswers,
    setIsLoading,
    countdown,
    activeStep,
    checkPermissions,
    handleNext,
    handleSaveQuestion,
    setCurrentQuestion,
    currentQuestion,
    handleReset,
    toggle,
    setToggle,
    startRecord,
    setStartRecord,
    questionTime,
    setQuestionTime,
    showAgainVideo,
    setShowAgainVideo,
    clearVideoInterview,
    isClearingInterview,
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
    hasPermission,
    setHasPermission,
    handleStopRecording,
    streamCamVideo,
    setSimpleLoader,
    simpleLoader,
    questionDrawer,
    setQuestionDrawer,
    evaluationDrawer,
    setEvaluationDrawer,
    setActiveStep,
    setStartInterview,
    selectedQuestions,
    releaseDevices,
    setTQuestion,
    selectedQType,
    setQType,
    videoAnalysis,
    paymentDrawer,
    setPaymentDrawer,
    completeProfileDrawer,
    setCompleteProfileDrawer,
    videoData,
    loader,
    language,
    setLanguage,
    setAnswerId,
    getResult,
    setResultId,
    setLoader,
    id,
    setId,
    stream,
    progressBar,
    progress,
    setProgress,
    mediaStreamRef,
    getInterviews,
    interviews,
    answerTestCode
  };
};
