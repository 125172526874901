import React, { useContext } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from '../style';
import Button from '../../../../common/Button';
import TextDictionary from '../../../../common/Dictionary';
import { CareerCoachInterviewContext } from '../CareerCoachInterviewContext';

const Styles = makeStyles((theme) => Style(theme));

export default function Instructions() {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const { toggle, setToggle, countdown, setStartRecord, streamCamVideo } =
    useContext(CareerCoachInterviewContext);

  const handleStart = () => {
    setToggle(false);
    streamCamVideo();
    setStartRecord(true);
  };

  return (
    <Dialog
      open={toggle}
      onClose={setToggle}
      className={classes.main__dialog__instructions}
    >
      <Stack
        p={2}
        pt={0}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <Typography
          pt={1}
          sx={{
            fontWeight: '600',
            textAlign: 'center',
            color: smMediaquery ? '#fff' : '#010103',
          }}
          variant={smMediaquery ? 'title26Bold' : 'title20Bold'}
        >
          You will have {countdown} seconds to read the question. The video
          recording will start automatically. When you are ready click start.
        </Typography>

        <Stack
          direction='row'
          pt={smMediaquery ? 18 : 4}
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          <Button
            width={smMediaquery ? '235px' : '100%'}
            height={smMediaquery ? '56px' : '40px'}
            title='Start'
            color={'primary'}
            variant='contained'
            borderRadius='11px'
            fontSize={'14px'}
            fontWeight='400'
            backgroundColor={
              smMediaquery ? '#010103 !important' : '#010103 !important'
            }
            borderStyle={smMediaquery && '1px solid #fff !important'}
            textColor={smMediaquery ? '#fff !important' : '#fff !important'}
            onClick={handleStart}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
}
