import React, { useState, useEffect, useRef, useContext } from 'react';

import PropTypes from 'prop-types';
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {
  Box,
  Divider,
  Stack,
  Typography,
  ClickAwayListener,
  MenuItem,
  useMediaQuery,
} from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import Avatars from '../../../common/Avatar';
import Context from '../../../store/Context';
import { sendVerificationOtp } from './functions';
import { LogoBlack } from '../../../assets/constants';
import CustomizationSection from './CustomizationSection';
import TemplateLoader from '../../../components/Seeker/TemplateLoader';
import { defaultHeaders, headers, usefetch } from '../../../helpers/fetch';
import Template1 from '../../../components/Seeker/CreateResume/Templates/Template1';
import ResumeTabs from '../../../components/Seeker/CreateResume/ResumeTabs/ResumeTabs';
import UpgradePremium from '../../../components/Seeker/AccountVerification/UpgradePremium';
import OTPVerification from '../../../components/Seeker/AccountVerification/OTPVerification';
import Template2 from '../../../components/Seeker/CreateResume/Templates/Template2';
import { useAuthContext } from '../../../Auth/useAuthContext';
import { AuthContext } from '../../../Auth/AuthContext';
import AvatarMenu from '../../../common/AvatarMenu';
import { useCvBuilderContext } from '../../../context/CvBuilderContext/useCvBuilderContext';
import { CartContext } from '../../Packages/Cart/CartContext';
import { readCookie } from '../../../helpers/CookieHelper';

const Styles = makeStyles((theme) => Style(theme));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
            height: '100%',
            paddingLeft: props.smMediaquery ? 0 : '.5rem',
          }}
        >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CvEdit() {
  const classes = Styles();
  const theme = useTheme();
  const tabRefs = useRef([]);
  const navigate = useNavigate();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  // const [cvInfo, setCVInfo] = useState([]);
  // const [activeTab, setActiveTab] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [otpPopup, showOtpPopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [premiumDialog, setPremiumDialog] = useState(false);
  const [globalState, globalDispatch] = useContext(Context);
  // const [currentTemplate, setCurrentTemplate] = useState('');
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { user, logout, refreshUserData } = useAuthContext();

  const {
    seekerOrder,
    payNow,
    checkCredits,
    seekerPackageAmount,
    seekerPackage,
  } = useContext(CartContext);

  const {
    getCv,
    activeTab,
    setActiveTab,
    currentTemplate,
    setCurrentTemplate,
    cvInfo,
    setCVInfo,
  } = useCvBuilderContext();

  // const { cvId } = useParams();

  const isDec = searchParams.get('is_dec');
  const cvId = searchParams.get('cvId');

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    // if (newValue === 0) {
    //   navigate(`/create-resume/template/1`);
    // }
    // if (newValue === 1) {
    //   navigate(`/create-resume/template/2`);
    // }
  };

  const getAllTemplates = () => {
    return usefetch('cv/getTemplates', 'post', defaultHeaders(), {}).then(
      (resp) => {
        setTemplates(resp?.data?.templates);
      },
    );
  };

  useEffect(() => {
    // sessionStorage.removeItem('cvId');
    // getUserStatus();
    getAllTemplates().then(() => {
      getCv();
    });
  }, []);

  useEffect(() => {
    getUserStatus();
  }, []);

  const sendOtp = () => {
    return usefetch('user/sendOtp', 'post', headers(), {}).then(function (
      resp,
    ) {
      return resp;
    });
  };

  const getUserStatus = () => {
    // if (!user?.email) {
    //   return false;
    // }

    // refreshUserData().then((user) => {
    //   if (user?.isEmailVerified !== 'Y') {
        // sendOtp();
        // showOtpPopup(true);
    //   }
    // });

    // usefetch('user/userStatus', 'post', headers(), {}).then(function (resp) {
    //   if (resp?.data?.userInfo?.is_email_verified == 'N') {
    //
    //     // sendVerificationOtp();
    //   }
    // });
  };

  // const getCv = () => {
  //   const data = isDec && isDec === 'Y' ? {cvIddec: cvId} : {cvId: cvId};
  //   usefetch('cv/getCvInfo', 'post', headers(), data).then(function (resp) {
  //     setCVInfo(resp?.data?.cvInfo);
  //     sessionStorage.setItem('cvId', resp?.data?.cvInfo?.cvIdEnc);
  //     setActiveTab(resp?.data?.cvInfo?.templateCode);
  //     setCurrentTemplate(resp?.data?.cvInfo?.templateCode);
  //   });
  // };

  const allTabs = [
    {
      title: 'Dashboard',
      component: <Template1 />,
      icon: 'Con',
    },
    {
      title: 'Template ',
      component: <Template2 />,
    },
    {
      title: 'Template 3',
      component: 'Template 3',
    },
  ];

  const OTPDialog = otpPopup && (
    <OTPVerification
      toggle={otpPopup}
      email={user?.email}
      setToggle={showOtpPopup}
    />
  );

  const isSuggested = readCookie('isSuggested');

  const handleCompleteApplication = () => {
    usefetch('jobs/completeJobApplication', 'post', headers(), {
      cvId: sessionStorage.getItem('cvId'),
      jid: sessionStorage.getItem('jid'),
      isSuggested: isSuggested,
    }).then(function (resp) {
      if (resp?.code === '11') {
        sessionStorage.removeItem('jid');
        navigate('/dashboard/my-applications');
      }
    });
  };

  const handleLogout = () => {
    logout();
    // localStorage.clear();
    // sessionStorage.clear();
    // navigate('/signup');
  };
  useEffect(() => {
    if (!seekerPackageAmount) {
      seekerPackage();
    }
  }, []);

  return (
    <Stack direction='row'>
      <Stack px={1} py={smMediaquery ? 3 : 5} className={classes.sidebar}>
        <Stack
          alignItems={smMediaquery ? 'center' : 'flex-start'}
          px={smMediaquery ? 2 : 5}
          pb={smMediaquery ? 2 : 4.6}
        >
          <LogoBlack style={{ height: smMediaquery ? '21px' : '31px' }} />
        </Stack>
        <Stack
          px={smMediaquery ? 2 : 5}
          sx={{
            height: 'calc(100vh - 220px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '5px',
              height: 0,
            },
            '&::-webkit-scrollbar-button': {
              height: '90px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#e4e4e400',
              boxShadow: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#d3d3d3',
              borderRadius: '16px',
            },
          }}
        >
          {console.log('checkCredits', checkCredits('CCV'))}
          {!checkCredits('CCV') && (
            <>
              <Stack>
                <Typography
                  variant='title20Bold'
                  sx={{
                    fontWeight: '600',
                    textAlign: smMediaquery ? 'center' : 'left',
                  }}
                >
                  Get a Premium Membership
                </Typography>
                <Typography
                  variant={mediaquery1380 ? 'title14Bold' : 'title16Bold'}
                  sx={{
                    color: '#252424',
                    fontWeight: '400',
                    lineHeight: mediaquery1380 ? '22px' : '28px',
                    textAlign: smMediaquery ? 'center' : 'left',
                  }}
                  pt={1.6}
                >
                  Get a Premium membership for just Rs.{' '}
                  {Number(seekerPackageAmount).toLocaleString('en-US')} for an
                  entire year. Here what you get.
                </Typography>
              </Stack>
              <Divider sx={{ my: 3 }} />
            </>
          )}

          <Stack>
            <Stack
              pb={1.2}
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography
                variant={smMediaquery ? 'title16Bold' : 'title18Bold'}
                sx={{ fontWeight: smMediaquery ? '500' : '600' }}
              >
                Premium CV Template
              </Typography>
              {!checkCredits('CCV') && (
                <Typography
                  variant='title12Bold'
                  sx={{
                    color: '#ec7376',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    seekerOrder().then((resp) => {
                      payNow(resp?.data?.orderId, resp?.data?.amount);
                    });
                  }}
                >
                  TRY IT
                </Typography>
              )}
            </Stack>
            <ResumeTabs
              getCv={getCv}
              templateData={cvInfo}
              templates={templates}
              setCurrentTemplate={setCurrentTemplate}
              allTabs={allTabs}
              a11yProps={a11yProps}
              activeTab={activeTab}
              handleChange={handleChange}
            />
          </Stack>

          <>
            <Divider sx={{ my: 3 }} />
            <CustomizationSection
              smMediaquery={smMediaquery}
              otpPopup={otpPopup}
              showOtpPopup={showOtpPopup}
              setPremiumDialog={setPremiumDialog}
            />
          </>
        </Stack>

        <Stack px={smMediaquery ? 2 : 5} pt={smMediaquery ? 3 : 6} spacing={2}>
          <Button
            width='100%'
            height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
            color='primary'
            className='w-100'
            fontSize={smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'}
            borderRadius='11px'
            variant='outlined'
            title='Customize'
            backgroundColor='#fff !important'
            borderStyle='1px solid #010103 !important'
            onClick={console.log('hi')}
          />
          {!checkCredits('CCV') && (
            <Button
              width='100%'
              height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
              color='primary'
              className='w-100'
              fontSize={
                smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'
              }
              borderRadius='11px'
              variant='contained'
              title='Upgrade to Premium'
              onClick={() => {
                seekerOrder().then((resp) => {
                  payNow(resp?.data?.orderId, resp?.data?.amount);
                });
              }}
            />
          )}

          {sessionStorage.getItem('jid') && (
            <Button
              width='100%'
              height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
              color='primary'
              className='w-100'
              fontSize={
                smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'
              }
              borderRadius='11px'
              variant='outlined'
              title='Complete Application'
              backgroundColor='#fff !important'
              borderStyle='1px solid #010103 !important'
              onClick={handleCompleteApplication}
            />
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
        className={classes.templates}
      >
        <TabPanel
          value={activeTab}
          index={activeTab}
          className={classes.tab__panel}
          ref={(el) => (tabRefs.current[activeTab] = el)}
        >
          {true ? (
            <Stack p={2} pr={5} position='relative' alignItems='flex-end'>
              <AvatarMenu />
            </Stack>
          ) : null}
          <TemplateLoader
            getCv={getCv}
            templateId={currentTemplate}
            templateData={cvInfo}
          />
          {/*{allTabs[activeTab].component}*/}
        </TabPanel>
      </Stack>
      {OTPDialog}
    </Stack>
  );
}
