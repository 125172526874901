import React, {createContext, useContext, useState, useEffect, lazy} from "react";
import TagManager from "react-gtm-module";
import { ThemeProvider } from "@mui/material/styles";

import MainRoutes from "./routes";
// import store from './store/index';

import theme from "./theme/theme";

import "./assets/scss/index.scss";
import SnackbarCustom from "./common/Snackbar";
import { CartProvider } from "./pages/Packages/Cart/CartProvider";
import Popup from "./pages/Accounts/UpgradePopup/Popup";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Auth/AuthProvider";
import { createCookie } from "./helpers/CookieHelper";
import { SITE_AT } from "./helpers/utils";
import { CvBuilderProvider } from "./context/CvBuilderContext/CvBuilderProvider";
import { SeekerChatProvider } from "./context/SeekerChatContext/SeekerChatProvider";
import MainLoader from "./common/Loader/MainLoader";
import { CareerCoachInterviewProvider } from "./pages/CareerCoach/VideoInterview/CareerCoachInterviewProvider";
import {PrivateRoute} from "./routes/PrivateRoutes";

const tagManagerArgs = {
  gtmId: "GTM-PXRQCJJD",
  // dataLayer: {
  //   userProject: 'project',
  // },
};

const SnackbarContext = createContext();
export const useSnackbar = () => useContext(SnackbarContext);

const GlobalLoaderContext = createContext();
export const useGlobalLoader = () => useContext(GlobalLoaderContext);

const App = () => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [globalLoader, setGlobalLoader] = useState(false);

  useEffect(() => {
    if (SITE_AT === "beta" || SITE_AT === "sailfish") {
      createCookie("flow", "new");
    }
  }, []);

  TagManager.initialize(tagManagerArgs);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarContext.Provider value={{ setSnackbarMessage }}>
          <GlobalLoaderContext.Provider value={{globalLoader, setGlobalLoader}}>
          <AuthProvider>
            <CartProvider>
              <SeekerChatProvider>
                <CvBuilderProvider>
                <CareerCoachInterviewProvider>
                  <MainRoutes />
                  <MainLoader globalLoader={globalLoader} setGlobalLoader={setGlobalLoader}/>
                  <SnackbarCustom
                    response={snackbarMessage}
                    handleClose={() => {
                      setSnackbarMessage("");
                    }}
                  />
                  </CareerCoachInterviewProvider>
                </CvBuilderProvider>
              </SeekerChatProvider>
            </CartProvider>
          </AuthProvider>
          </GlobalLoaderContext.Provider>
        </SnackbarContext.Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
