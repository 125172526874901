const Style = (theme) => ({
  main__dialog__verification: {
    '& .MuiDialog-paper': {
      width: '350px !important',
      height: '450px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  set__password__dialog: {
    '& .MuiDialog-paper': {
      height: '450px !important',
    },
  },
  main__dialog__success: {
    '& .MuiDialog-paper': {
      width: '350px !important',
      height: '304px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  upgrade__premium: {
    '& .MuiDialog-paper': {
      width: '710px !important',
      maxWidth: '710px !important',
      height: '340px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  main__dialog__success: {
    '& .MuiDialog-paper': {
      width: '339px !important',
      height: '283px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  main__dialog__success__custom: {
    '& .MuiDialog-paper': {
      width: '360px !important',
      height: '280px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
});
export default Style;
