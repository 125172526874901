import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Typography, Stack } from '@mui/material';

export default function SuccessScreen() {
  const navigate = useNavigate();
  return (
    <Stack
      color='primary.light'
      pt={3}
      pb={7}
      px={1.8}
      direction='column'
      height='100%'
      width='100%'
      justifyContent='flex-start'
      alignItems='center'
      sx={{ background: '#010103', height: '100%', overflowY: 'auto' }}
    >
      <Stack
        width='100%'
        height='100%'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          pt={4}
          pb={6}
          variant='title28Bold'
          sx={{ fontWeight: '700', textAlign: 'center' }}
        >
          Thank you. One of our representatives will be in touch with you
          shortly.
        </Typography>
        <Typography
          mt={5}
          variant='title14Bold'
          sx={{ fontWeight: '400' }}
          onClick={() => navigate(-1)}
        >
          Back
        </Typography>
      </Stack>
    </Stack>
  );
}
