import React, { useState, useEffect } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { Drawer, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from './style';
import { useGetPayments } from '../../../services/useGetPayments';
import { useLogPayment } from '../../../services/useLogPayment';
import { useNavigate } from 'react-router-dom';

const Styles = makeStyles((theme) => Style(theme));

export default function Payment({ toggle, setPaymentToggle }) {
  const classes = Styles();
  const theme = useTheme();
  const navigate = useNavigate();
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));
  const [supportMessage, setSupportMessage] = useState(false);
  const { logPayment } = useLogPayment();

  const { getPayments, payments } = useGetPayments();
  useEffect(() => {
    getPayments();
  }, []);

  return (
    <Drawer
      anchor='bottom'
      open={toggle}
      className={classes.payment__dialog}
      sx={{
        '--Drawer-transitionDuration': toggle ? '1s' : '0.1s',
      }}
    >
      <Stack
        color='primary.light'
        pt={3}
        pb={7}
        px={1.8}
        direction='column'
        height='100%'
        width='100%'
        justifyContent='flex-start'
        alignItems='center'
        sx={{ height: '100%', overflowY: 'auto' }}
      >
        <Stack>
          <Typography
            pt={4}
            pb={10}
            variant='title26Bold'
            sx={{ fontWeight: '700', textAlign: 'center' }}
          >
            Upgrade to our premium membership to enjoy more questions, attempts,
            and AI coaching.
          </Typography>
          <Stack width='100%' spacing={2}>
            {payments?.map((k, i) => {
              return (
                <Stack
                  key={k?.amount + i}
                  onClick={() => {
                    logPayment({
                      paymentId: k?.id,
                      action: 'payment',
                    });
                    navigate('/career-coach/success');
                  }}
                  p={3}
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                  sx={{ borderRadius: '11px', border: '1px solid #fff' }}
                >
                  <Typography
                    pb={0.7}
                    variant='title24Bold'
                    sx={{ fontWeight: '500' }}
                  >
                    Rs. {k?.amount}
                  </Typography>
                  <Typography variant='title16Bold' sx={{ fontWeight: '500' }}>
                    {k?.questionsCount} questions - {k?.retry} attempts per
                    question
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
          <Typography
            mt={5}
            variant='title14Bold'
            sx={{ fontWeight: '400', textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {
              logPayment({
                action: 'nothankyou',
              });
              setPaymentToggle(false);
            }}
          >
            No Thanks
          </Typography>
        </Stack>
      </Stack>
    </Drawer>
  );
}
