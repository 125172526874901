import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultHeaders, usefetch } from '../helpers/fetch';

export const useGetVideoInterviewQuestions = () => {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [isGettingQuestions, setIsGettingQuestions] = useState(null);
  const [response, setResponse] = useState(null);
  const [videoInterviewQuestions, setVideoInterviewQuestions] = useState([]);
  const [jobTitle, setJobTitle] = useState(null);
  const [reRecord, setRerecord] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [isVideoInterviewCompleted, setIsVideoInterviewCompleted] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const getVideoInterviewQuestions = async (params) => {
    setIsGettingQuestions(true);
    const data = {
      jid: params?.jid,
      testCode: params?.testCode,
      type: params?.type,
      ansLang: params?.ansLang
    };

    const resp = await usefetch(
      'videoInterview/getVideoInterviewQuestions',
      'POST',
      defaultHeaders(),
      data,
    );

    if (resp?.code === '11') {
      // setIsLoading(false);
      setVideoInterviewQuestions(resp?.data?.questions);
      // if (!params?.jid) {
      console.log(resp?.data);
      console.log('resp?.data?.reRecord', resp?.data?.reRecord);
      console.log('resp?.data?.countdown', resp?.data?.countdown);

      setJobTitle(resp?.data?.jobTitle);
      setRerecord(resp?.data?.reRecord);
      setCountdown(resp?.data?.countdown);
      // }
    }

    if (resp?.code === '05') {
      setIsVideoInterviewCompleted(true);
    }

    if (!resp.ok) {
      setError(resp.error);
    }

    if (resp.ok) {
      setResponse((prevData) => [...prevData, resp]);
    }

    setIsGettingQuestions(false);
    return resp;
  };

  return {
    getVideoInterviewQuestions,
    response,
    reRecord,
    videoInterviewQuestions,
    jobTitle,
    countdown,
    isGettingQuestions,
    isVideoInterviewCompleted,
    setIsVideoInterviewCompleted,
    error,
    isLoading,
    setIsLoading,
    setCountdown,
    setRerecord,
  };
};
