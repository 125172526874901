import {useState} from "react";
import {headers,defaultHeaders, usefetch} from "../helpers/fetch";

export const useStartVideoInterview = () => {
    const [error, setError] = useState(null);
    const [isStartingInterview, setIsStartingInterview] = useState(null);
    const [response, setResponse] = useState(null);
    const [isStarted, setIsStarted] = useState(false);

    const startVideoInterview = async (params) => {
        setIsStartingInterview(true);
        const data = {
            testCode: params?.testCode,
        };

        const resp = await usefetch('videoInterview/startTest', 'POST', defaultHeaders(), data);

        if (resp?.code === '11') {
            setIsStarted(true)
        }

        if (!resp.ok) {
            setError(resp.error);
        }

        if (resp.ok) {
            setResponse((prevData) => [...prevData, resp]);
        }

        setIsStartingInterview(false);
        return resp;
    };

    return { startVideoInterview, response, isStarted, isStartingInterview, error };
};