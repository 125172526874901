import React from 'react';
import { Button } from '@mui/material';

export default function index({
  title,
  color,
  width,
  height,
  variant,
  onClick,
  loading,
  endIcon,
  fontSize,
  disabled,
  children,
  className,
  startIcon,
  textColor,
  fontWeight,
  borderStyle,
  borderRadius,
  loadingColor,
  textTransform,
  backgroundColor,
  disableLabelPadding,
}) {
  return (
    <Button
      className={className}
      color={color}
      disableRipple
      disableFocusRipple
      disableElevation
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        width: width,
        height: height,
        fontWeight: fontWeight ? fontWeight : '600',
        textTransform: textTransform ? textTransform : 'capitalize',
        color: textColor && textColor,
        border: borderStyle && borderStyle,
        fontSize: fontSize ? fontSize : '18px',
        background: backgroundColor && backgroundColor,
        borderRadius: borderRadius ? borderRadius : '42px',
      }}
    >
      <span
        style={{
          paddingTop: disableLabelPadding ? '0' : startIcon ? '.2rem' : '0',
        }}
      >
        {title}{' '}
      </span>
      {children}
    </Button>
  );
}
