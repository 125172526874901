const Style = (theme) => ({
  select__question__dialog: {
    width: '100vw !important',
    height: '100vh',
    '& .MuiDrawer-paper': {
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '0px',
      width: '100% !important',
      height: '100%',
      boxShadow: 'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  dropdown__main: {
    width: '100%',
    backgroundColor: '#fff',
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      paddingRight: '9px',
      height: '48px',
      '& fieldset': {
        borderWidth: '1px !important',
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        right: '9px',
        pointerEvents: 'none !important',
      },
      '& .MuiSelect-select': {
        textAlign: 'left',
        paddingRight: '13px !important',
        fontSize: '14px',
        fontWeight: '400',
      },
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#010103',
      '& .MuiSelect-select': {
        color: '#010103',
        WebkitTextFillColor: '#010103',
      },
    },
    '& fieldset': {
      borderColor: '#acacac !important',
    },
  },
  choose__btn: {
    '& .MuiButtonBase-root': {
      width: '100%',
      height: '56px',
      fontSize: '14px',
      fontWeight: '400',
      borderRadius: '11px',
      backgroundColor: '#010103 !important',
      color: '#fff !important',
    },
    '& .MuiButtonBase-root.Mui-disabled': {
      color: '#868686 !important',
      backgroundColor: '#cecece !important',
    },
  },
  select__option__drawer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDrawer-paper': {
      position: 'relative',
      padding: '0',
      borderRadius: '10px 10px 0 0',
      width: '100% !important',
      height: '90vh',
      boxShadow: 'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
});
export default Style;
