import React, { useContext, useRef, useState } from 'react';

import TextField from '@mui/material/TextField';
import { makeStyles, useTheme } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import {
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import {
  CheckboxCheckedIcon,
  CheckboxIcon,
  UpgradeCrown,
} from '../../../assets/constants';
import { headers, usefetch } from '../../../helpers/fetch';
import PaymentCard from '../../../assets/images/payment-card-new.png';
import PaymentBank from '../../../assets/images/payment-bank.png';
import PaymentWallet from '../../../assets/images/payment-wallet.png';
import PaymentPaypal from '../../../assets/images/payment-paypal.png';
import PaymentPaypro from '../../../assets/images/payment-paypro.png';
import { CartContext } from '../../../pages/Packages/Cart/CartContext';
import PaymentJazzcash from '../../../assets/images/payment-jazzcash.png';
import { sendVerificationOtp } from '../../../pages/Seeker/CreateResume/functions';
import SuccessPopup from './SuccessPopup';

const Styles = makeStyles((theme) => Style(theme));

export default function UpgradePremium({
  toggle,
  setToggle,
  calledFrom = 'employer',
}) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  const [checked, setChecked] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);

  const { payNowPopup, setPayNowPopup, PaymentData, seekerFlow, OrderPayment } =
    useContext(CartContext);
  const handleUpgrade = async (gateway) => {
    const response = await usefetch('user/premiumUpgrade', 'post', headers(), {
      gateway: gateway,
    });
    if (response?.code === '11') {
      setPayNowPopup(false);
    }
  };

  const formRef = useRef(null);

  const handleSubmit = () => {
    // if (calledFrom == 'seeker') {
    //   return false;
    // }
    formRef.current.submit();
  };

  const PaymentForm = () => (
    <div>
      <form
        ref={formRef}
        id='PayFast_payment_form'
        action={PaymentData?.postURL}
        method='POST'
      >
        <TextField
          name='CURRENCY_CODE'
          value={PaymentData?.curr}
          style={{ display: 'none' }}
        />
        <TextField
          name='MERCHANT_ID'
          value={PaymentData?.merchantId}
          style={{ display: 'none' }}
        />
        <TextField
          name='TOKEN'
          value={PaymentData?.token}
          style={{ display: 'none' }}
        />
        <TextField
          name='SUCCESS_URL'
          value={PaymentData?.successURL}
          style={{ display: 'none' }}
        />
        <TextField
          name='FAILURE_URL'
          value={PaymentData?.failureURL}
          style={{ display: 'none' }}
        />
        <TextField
          name='CHECKOUT_URL'
          value='https://typedwebhook.'
          style={{ display: 'none' }}
        />
        <TextField
          name='CUSTOMER_EMAIL_ADDRESS'
          value={PaymentData?.customer?.email}
          style={{ display: 'none' }}
        />
        <TextField
          name='CUSTOMER_MOBILE_NO'
          value={PaymentData?.customer?.mobile}
          style={{ display: 'none' }}
        />
        <TextField
          name='TXNAMT'
          value={PaymentData?.trans?.amount}
          style={{ display: 'none' }}
        />
        <TextField
          name='BASKET_ID'
          value={PaymentData?.trans?.basketId}
          style={{ display: 'none' }}
        />
        <TextField
          name='ORDER_DATE'
          value={PaymentData?.date}
          style={{ display: 'none' }}
        />
        <TextField
          name='SIGNATURE'
          value={PaymentData?.randString}
          style={{ display: 'none' }}
        />
        <TextField
          name='VERSION'
          value={PaymentData?.version}
          style={{ display: 'none' }}
        />
        <TextField
          name='TXNDESC'
          value={PaymentData?.trans?.description}
          style={{ display: 'none' }}
        />
        <TextField name='PROCCODE' value='00' style={{ display: 'none' }} />
        <TextField
          name='TRAN_TYPE'
          value='ECOMM_PURCHASE'
          style={{ display: 'none' }}
        />
        <TextField name='STORE_ID' value='' style={{ display: 'none' }} />
        <TextField
          name='RECURRING_TXN'
          value='false'
          style={{ display: 'none' }}
        />
      </form>
    </div>
  );

  function handleCheckBox(index) {
    setChecked(index);
  }

  const SuccessPopupMain = successPopup && (
    <SuccessPopup toggle={successPopup} setToggle={setSuccessPopup} />
  );

  return (
    <Dialog
      open={payNowPopup}
      onClose={setPayNowPopup}
      className={classes.upgrade__premium}
    >
      <Stack
        justifyContent='flex-end'
        direction='row'
        position='absolute'
        right='.5rem'
        top='0.5rem'
      >
        <CloseRoundedIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setPayNowPopup(false)}
        />
      </Stack>
      <Stack
        p={3}
        direction='row'
        height='100%'
        justifyContent='space-between'
        alignItems='center'
        color='primary.main'
      >
        <Stack height='max-content' width='54%'>
          {false && (
            <Typography
              pb={3}
              variant='title16Bold'
              sx={{ display: 'flex', fontWeight: '400', alignItems: 'center' }}
            >
              {' '}
              <ArrowBackIcon style={{ marginRight: '.5rem' }} /> Back
            </Typography>
          )}

          {seekerFlow ? (
            <>
              <Typography
                py={2}
                variant='title16Bold'
                sx={{
                  display: 'flex',
                  fontWeight: '400',
                  alignItems: 'center',
                }}
              >
                <UpgradeCrown style={{ marginRight: '.5rem' }} />
                Upgrade To Premium
              </Typography>
              <Typography pt={1.2} variant='title28Bold' pb={2}>
                Try RozeeGPT Premium
              </Typography>
              <Typography
                pb={4}
                variant='title16Bold'
                sx={{ fontWeight: '300', lineHeight: '24px' }}
              >
                RozeeGPT Premium Membership Rs.{' '}
                {Number(OrderPayment).toLocaleString('en-US')}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                py={2}
                variant='title16Bold'
                sx={{
                  display: 'flex',
                  fontWeight: '400',
                  alignItems: 'center',
                }}
              >
                <UpgradeCrown style={{ marginRight: '.5rem' }} />
                Elevate your hiring process
              </Typography>
              <Typography pt={1.2} variant='title28Bold' pb={2}>
                Buy Hiring Tools
              </Typography>
              <Typography
                pb={4}
                variant='title16Bold'
                sx={{ fontWeight: '300', lineHeight: '24px' }}
              >
                Pay now and start hiring Effortlessly!
              </Typography>
            </>
          )}

          <Button
            variant='contained'
            backgroundColor='#010103 !important'
            textColor='#fff !important'
            borderRadius='11px'
            fontSize='16px'
            height='52px'
            width='273px'
            onClick={() => handleSubmit()}
            title='Pay'
          />
          <PaymentForm />
        </Stack>
        <Stack width='43%'>
          <Typography variant='title16Bold' sx={{ fontWeight: '500' }} pb={2.4}>
            Payment Method
          </Typography>
          <Stack
            p={2}
            sx={{
              border: '1px solid #e4e4e4',
              borderRadius: '15px',

              '& .form__group': {
                '& .MuiFormControlLabel-root': {
                  margin: '0 !important',
                },
              },
            }}
          >
            <Stack
              pb={2}
              width='100%'
              direction='row'
              alignItems='center'
              sx={{
                cursor: 'pointer',
                '& .logo': {
                  height: '31px',
                },
                borderBottom: '1px solid #e4e4e4',
              }}
              onClick={(e) => handleCheckBox(1)}
            >
              <FormGroup className='form__group'>
                <FormControlLabel
                  label=''
                  control={
                    <Checkbox
                      sx={{ padding: '.35rem' }}
                      onChange={(e) => handleCheckBox(1)}
                      icon={
                        <RadioButtonUncheckedIcon sx={{ color: '#d3d3d3' }} />
                      }
                      checkedIcon={
                        <RadioButtonCheckedIcon sx={{ color: '#0aa06a' }} />
                      }
                      checked={checked === 1}
                    />
                  }
                />
              </FormGroup>
              <Typography
                sx={{ width: 'calc(100% - 25px)' }}
                variant='title14Bold'
              >
                Card Payment
              </Typography>
              <img src={PaymentCard} alt='card' srcset='' className='logo' />
            </Stack>
            <Stack
              py={1.8}
              width='100%'
              direction='row'
              alignItems='center'
              sx={{
                cursor: 'pointer',
                '& .logo': {
                  height: '35px',
                  width: '48px',
                },
                borderBottom: '1px solid #e4e4e4',
              }}
              onClick={(e) => handleCheckBox(2)}
            >
              <FormGroup className='form__group'>
                <FormControlLabel
                  label=''
                  control={
                    <Checkbox
                      sx={{ padding: '.35rem' }}
                      onChange={(e) => handleCheckBox(2)}
                      icon={
                        <RadioButtonUncheckedIcon sx={{ color: '#d3d3d3' }} />
                      }
                      checkedIcon={
                        <RadioButtonCheckedIcon sx={{ color: '#0aa06a' }} />
                      }
                      checked={checked === 2}
                    />
                  }
                />
              </FormGroup>
              <Typography sx={{ width: 'calc(100% - 25px)' }}>
                Bank Account
              </Typography>
              <img
                src={PaymentBank}
                alt='card'
                srcset=''
                className='logo'
                height='35px !important'
              />
            </Stack>
            <Stack
              py={1.8}
              width='100%'
              direction='row'
              alignItems='center'
              sx={{
                cursor: 'pointer',
                '& .logo': {
                  height: '27px',
                },
                borderBottom: '1px solid #e4e4e4',
              }}
              onClick={(e) => handleCheckBox(3)}
            >
              <FormGroup className='form__group'>
                <FormControlLabel
                  label=''
                  control={
                    <Checkbox
                      sx={{ padding: '.35rem' }}
                      onChange={(e) => handleCheckBox(3)}
                      icon={
                        <RadioButtonUncheckedIcon sx={{ color: '#d3d3d3' }} />
                      }
                      checkedIcon={
                        <RadioButtonCheckedIcon sx={{ color: '#0aa06a' }} />
                      }
                      checked={checked === 3}
                    />
                  }
                />
              </FormGroup>
              <Typography sx={{ width: 'calc(100% - 25px)' }}>
                Wallets and Others
              </Typography>
              <img src={PaymentWallet} alt='card' srcset='' className='logo' />
            </Stack>

            <Stack
              pt={1.8}
              width='100%'
              direction='row'
              alignItems='center'
              sx={{
                cursor: 'pointer',
                '& .logo': {
                  height: '35px',
                  width: '48px',
                },
              }}
              onClick={(e) => handleCheckBox(4)}
            >
              <FormGroup className='form__group'>
                <FormControlLabel
                  label=''
                  control={
                    <Checkbox
                      sx={{ padding: '.35rem' }}
                      onChange={(e) => handleCheckBox(4)}
                      icon={
                        <RadioButtonUncheckedIcon sx={{ color: '#d3d3d3' }} />
                      }
                      checkedIcon={
                        <RadioButtonCheckedIcon sx={{ color: '#0aa06a' }} />
                      }
                      checked={checked === 4}
                    />
                  }
                />
              </FormGroup>
              <Typography sx={{ width: 'calc(100% - 17px)' }}>Raast</Typography>
              <img src={PaymentPaypro} alt='card' srcset='' className='logo' />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {SuccessPopupMain}
    </Dialog>
  );
}
