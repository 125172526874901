import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/styles';
import { Stack, Typography, useMediaQuery } from '@mui/material';

import { useCountdown } from './useCountdown';
import TextDictionary from '../../../../common/Dictionary';

export default function Countdown(props) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  const {
    setStartRecord,
    setQuestionTime,
    question,
    startRecording,
    codingStack,
    countdown,
  } = props;

  const { getCountdownTime } = useCountdown({
    setStartRecord,
    setQuestionTime,
    question,
    startRecording,
    count: countdown,
  });

  return (
    getCountdownTime() && (
      <>
        <Stack
          sx={{
            display: codingStack ? 'none' : 'block',
            position: 'fixed',
            bottom: smMediaquery ? 'unset' : '3rem',
            top: smMediaquery ? '52%' : 'unset',
            left: smMediaquery ? '25px' : '36px',
            zIndex: 9,
            width: 'calc(100% - 36px)',
          }}
        >
          <Typography
            pb={2}
            variant={smMediaquery ? 'title14Bold' : 'title16Bold'}
            sx={{
              color: '#fff',
              fontWeight: '500',
              width: smMediaquery ? '100%' : '32%',
            }}
          >
            Recording will automatically starts in{' '}
            <span id='video__interview__countdown'></span> seconds
          </Typography>
        </Stack>
        <Stack
          sx={{
            position: 'absolute',
            top: smMediaquery ? '7rem' : '50%',
            left: '50%',
            zIndex: 9,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography
            sx={{
              fontSize: smMediaquery ? '74px' : '134px',
              fontWeight: '500',
              lineHeight: '136px',
              color: '#fff',
            }}
          >
            {' '}
            {getCountdownTime()}
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: codingStack ? 'block' : 'none',
            position: 'absolute',
            bottom: '5rem',
          }}
        >
          <Typography
            variant='title14Bold'
            sx={{
              fontWeight: '400',
              backgroundColor: '#1d1d3900',
              border: '1px solid #fff',
              color: '#fff',
              borderRadius: '50px',
              height: '56px',
              width: '235px',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Waiting to start
          </Typography>
        </Stack>
      </>
    )
  );
}
