import React, { useContext, useEffect } from 'react';

import { Button, CircularProgress, Stack, useMediaQuery } from '@mui/material';
import { Timer } from '../Timer/Timer';
import VideoStack from './VideoStack';
import VideoAction from './VideoAction';
import StepperStack from './StepperStack';
import Instructions from './Instructions';
import Countdown from '../Countdown/Countdown';
import { CareerCoachInterviewContext } from '../CareerCoachInterviewContext';
import { useTheme } from '@emotion/react';

export default function VideoSection() {
  const {
    status,
    stopRecording,
    currentQuestion,
    videoRef,
    mediaStreamRef,
    mediaBlobUrl,
    activeStep,
    videoInterviewQuestions,
    isSavingAnswer,
    handleNext,
    countdown,
    toggle,
    setToggle,
    startRecord,
    setStartRecord,
    questionTime,
    setQuestionTime,
    showAgainVideo,
    setShowAgainVideo,
    isClearingInterview,
    startRecording,
    handleClear,
    streamCamVideo,
  } = useContext(CareerCoachInterviewContext);
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  const Instruction = toggle && <Instructions />;

  return (
    <Stack sx={{ position: 'relative' }}>
      {startRecord && (
        <Countdown
          setStartRecord={setStartRecord}
          setQuestionTime={setQuestionTime}
          startRecording={startRecording}
          question={currentQuestion}
          countdown={countdown}
        />
      )}
      <Stack
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          height: '100%',
          width: '100%',
          background: startRecord ? 'rgba(0,0,0,0.02)' : 'rgba(0,0,0,0.02)',
          zIndex: '4',
        }}
      />
      <Stack
        alignItems='center'
        sx={{
          position: 'absolute',
          bottom: smMediaquery ? '6rem' : '3rem',
          width: '100%',
          zIndex: 9,
        }}
      >
        {smMediaquery ? (
          <Stack
            sx={{
              maxHeight: smMediaquery ? '251px' : '300px',
              overflowY: 'auto',
              position: 'fixed',
              bottom: '18rem',
            }}
          >
            <StepperStack />
          </Stack>
        ) : null}
        <Stack
          width='100%'
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            zIndex: 9,
          }}
        >
          {startRecord ? null : (
            <Timer
              questionTime={questionTime}
              stopRecording={stopRecording}
              setShowAgainVideo={setShowAgainVideo}
              setQuestionTime={setQuestionTime}
              actualQuestionTime={currentQuestion?.time}
              status={status}
            />
          )}
          <Stack py={0.7} />
          <VideoAction />
        </Stack>
      </Stack>
      <VideoStack
        status={status}
        mediaBlobUrl={mediaBlobUrl}
        showAgainVideo={showAgainVideo}
        videoRef={videoRef}
      />
      {Instruction}
    </Stack>
  );
}
