import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/styles';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Stack, Checkbox, Typography, useMediaQuery } from '@mui/material';
import { useStartVideoInterview } from '../../../services/useStartVideoInterview';

import Timer from './Timer';
import Button from '../../../common/Button';
import Loader from '../../../common/Loader';
import { LogoBlack } from '../../../assets/constants';
import { defaultHeaders, usefetch } from '../../../helpers/fetch';
import { useGetTestStatus } from '../../../services/useGetTestStauts';
import {useDisableUserActions} from "../../../helpers/hooks/useDisableRightClick";

export default function TestScreen({ setTestCompleted }) {
  useDisableUserActions();

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const { id, index } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [checkedAnswer, setCheckedAnswer] = useState({});
  const {startVideoInterview, isStarted} = useStartVideoInterview();
  const { getTestStatus, videoInterViewInfo, mcqInfo, isTestStatusLoading } = useGetTestStatus();


  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    // Function to handle before unload event
    const handleBeforeUnload = (event) => {
      // Standard message for the confirmation dialog
      const message =
        'Are you sure you want to leave this page? You will lose your progress.';
      // Set the returnValue property to display the confirmation message
      event.returnValue = message;
      // Return the message for browsers that support it
      return message;
    };

    // Add the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const getQuestions = () => {
    setLoading(true);
    usefetch('testBuilder/getQuestions', 'post', defaultHeaders(), {
      testCode: id,
    }).then(function (resp) {
      setLoading(false);
      if (resp?.code === '11') {
        setQuestions(resp?.data?.questions);
        setData(resp?.data);

        let tempCheckedItems = [];
        resp?.data?.questions?.map((question, index) => {
          if (question?.answerInfo?.answer_id) {
            const newStartIndex = index + 1;
            if (newStartIndex < resp?.data?.questions?.length) {
              setStartIndex(newStartIndex);
              // navigate(`/attempt-test/${id}/question/${newStartIndex}`);
              const newProgress = (newStartIndex / questions.length) * 100;
            }

            question?.answer?.map((ans, i) => {
              if (question?.answerInfo?.answer_id === ans?.id) {
                // handleCheckboxChange(index, i);
                tempCheckedItems[index] = i;
              }
            });
          }
        });

        setCheckedItems(tempCheckedItems);
      }
      if (resp?.code === '05') {
        setTestCompleted(true);
      }
    });
  };

  const [checkedItems, setCheckedItems] = useState(
    Array(questions?.length).fill(null),
  );
  // console.log(progress);
  const showNextItems = () => {
    let answerId = questions[startIndex]?.answer[checkedItems[startIndex]]?.id;

    return handleSaveQuestion(id, questions[startIndex]?.id, answerId).then(
      (resp) => {
        if (resp?.code === '11') {
          const newStartIndex = startIndex + 1;
          if (newStartIndex < questions?.length) {
            setStartIndex(newStartIndex);
            const newProgress = (newStartIndex / questions.length) * 100;
          }
        }

        return resp;
      },
    );
  };

  const handlePrevItems = () => {
    const newStartIndex = startIndex - 1;
    if (newStartIndex >= 0) {
      setStartIndex(newStartIndex);
      const newProgress = (newStartIndex / questions.length) * 100;
    }
  };
  const visibleItems = questions?.slice(startIndex, startIndex + 1);

  const handleCheckboxChange = (questionIndex, optionIndex) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[questionIndex] = optionIndex;
    setCheckedItems(newCheckedItems);
  };

  const handleSaveQuestion = (testCode, questionId, answerId) => {
    let data = {
      testCode: testCode,
      questionId: questionId,
      answerId: answerId,
    };

    return usefetch(
      'testBuilder/saveAnswer',
      'post',
      defaultHeaders(),
      data,
    ).then(function (resp) {
      return resp;
    });
  };

  const handleSubmitTest = async () => {
    let data = {
      testCode: id,
    };
    showNextItems().then(() => {
      return usefetch(
          'testBuilder/completeTest',
          'post',
          defaultHeaders(),
          data,
      ).then(async function (resp) {
        if (resp?.code === '11') {
          // getTestStatus({testCode: id}).then((resp) => {
          //   if(resp?.data?.videoInterViewInfo?.testStatus === 'pending' && resp?.data?.videoInterViewInfo?.showVideo === 'after') {
          //     startVideoInterview({testCode: resp?.data?.videoInterViewInfo?.testCode });
          //     navigate(`/video-interview/${resp?.data?.videoInterViewInfo?.testCode}`);
          //   } else {
          //     setTestCompleted(true);
          //   }
          // });

          return usefetch('testBuilder/appTest', 'post', defaultHeaders(), {
            testCode: id,
          }).then(function (resp) {
            if (resp?.code === '11') {
              const testInfo = resp?.data;
              if (testInfo?.testCode) {
                navigate(`/attempt-test/${testInfo?.testCode}`);
                return;
              } else {
                setTestCompleted(true);
              }
            }
          });
        }
        return resp;
      });



    });
  };

  const ButtonReuseable = () => {
    return (
      <Stack
        spacing={2}
        direction='row'
        justifyContent={smMediaquery && 'center'}
      >
        {startIndex === 0 ? null : (
          <Button
            color={'primary'}
            title='Back'
            variant='outlined'
            width='146px'
            height={smMediaquery ? '47px' : '52px'}
            fontSize='16px'
            backgroundColor={
              smMediaquery ? '#fff !important' : '#010103 !important'
            }
            borderStyle={
              smMediaquery
                ? '1px solid #010103 !important'
                : '1px solid #fff !important'
            }
            textColor={smMediaquery ? ' #010103 !important' : '#fff !important'}
            disabled={startIndex === 0}
            onClick={() => {
              handlePrevItems(false);
              // navigate(`/attempt-test/${id}/question/${nextQuestion - 1}`);
            }}
          />
        )}
        {startIndex + 1 >= questions?.length ? (
          <Button
            color={'primary'}
            title='Submit'
            variant='outlined'
            width='146px'
            height={smMediaquery ? '47px' : '52px'}
            fontSize='16px'
            onClick={handleSubmitTest}
            backgroundColor='#010103 !important'
            borderStyle={
              smMediaquery
                ? '1px solid #010103 !important'
                : '1px solid #fff !important'
            }
            textColor={'#fff !important'}
          />
        ) : (
          <Button
            color={'primary'}
            title='Next'
            variant='otlined'
            width='146px'
            height={smMediaquery ? '47px' : '52px'}
            disabled={!(checkedItems[startIndex] >= 0)}
            fontSize='16px'
            onClick={() => {
              showNextItems();
              // navigate(`/attempt-test/${id}/question/${nextQuestion + 1}`);
            }}
            backgroundColor='#010103 !important'
            borderStyle={
              smMediaquery
                ? '1px solid #010103 !important'
                : '1px solid #fff !important'
            }
            textColor={'#fff !important'}
          />
        )}
      </Stack>
    );
  };
  const nextQuestion = startIndex + 1;
  return (
    <Stack
      sx={{ background: smMediaquery ? '#fff' : '#F3F3F3' }}
      color='primary.main'
      height='100vh'
      width='100vw'
    >
      <Stack py={3} px={smMediaquery ? 3.5 : 10}>
        <LogoBlack
          style={{
            width: smMediaquery ? '175px' : '202px',
            height: smMediaquery ? '25px' : '31px',
          }}
        />
      </Stack>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Stack sx={{ height: smMediaquery && 'calc(100% - 135px)' }}>
            <Stack
              py={2}
              px={smMediaquery ? 4 : 10}
              color='primary.light'
              direction='row'
              alignItems='center'
              justifyContent={smMediaquery ? 'center' : 'space-between'}
              sx={{ background: '#010103' }}
            >
              <Stack
                direction={smMediaquery ? 'column' : 'row'}
                alignItems={smMediaquery ? 'center' : 'center'}
              >
                <Timer
                  remainingTime={data?.remainingTime}
                  testCode={id}
                  setTestCompleted={setTestCompleted}
                />
                <Typography
                  pl={smMediaquery ? 0 : 2}
                  variant={
                    smMediaquery
                      ? 'title14Bold'
                      : mediaquery1380
                      ? 'title20Bold'
                      : 'title24Bold'
                  }
                  sx={{ fontWeight: '400' }}
                >
                  <b> Online Test: </b>
                  {data?.jobTitle}
                </Typography>
              </Stack>

              {smMediaquery ? null : <ButtonReuseable />}
            </Stack>
            {visibleItems?.map((k, i) => {
              return (
                <Stack
                  pt={smMediaquery ? 3 : 6}
                  pb={3}
                  pr={smMediaquery ? 3.5 : 10}
                  key={i++}
                  sx={{
                    marginLeft: smMediaquery
                      ? '2rem'
                      : mediaquery1380
                      ? '276px'
                      : '306px',
                  }}
                >
                  <Typography
                    variant={smMediaquery ? 'title16Bold' : 'title18Bold'}
                    sx={{ fontWeight: '700' }}
                  >
                    Question {startIndex + 1}/{questions?.length}
                  </Typography>
                  <Typography
                    pt={2}
                    pb={1.3}
                    variant={
                      smMediaquery
                        ? 'title16Bold'
                        : mediaquery1380
                        ? 'title28Bold'
                        : 'title34Bold'
                    }
                    sx={{ fontWeight: '400' }}
                  >
                    {k?.question}
                  </Typography>

                  {k?.answer?.map((answerObj, index) => {
                    return (
                      <Stack py={smMediaquery ? 0.5 : 1.2} key={answerObj?.id}>
                        <Stack
                          onClick={() => {
                            handleCheckboxChange(startIndex, index);
                            setCheckedAnswer(k?.answer[index]);
                          }} // Pass both question and option index
                          pb={0.5}
                          direction='row'
                          alignItems='base-line'
                          width={smMediaquery ? '100%' : 'max-content'}
                          maxWidth='100%'
                          sx={{ cursor: 'pointer' }}
                        >
                          <Checkbox
                            {...label}
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                            checked={checkedItems[startIndex] === index} // Check against the corresponding question's index
                            sx={{ padding: '0' }}
                          />
                          <Typography
                            mt={smMediaquery && 0.2}
                            pl={1.2}
                            color='primary.main'
                            variant={
                              smMediaquery
                                ? 'title14Bold'
                                : mediaquery1380
                                ? 'title18Bold'
                                : 'title20Bold'
                            }
                            sx={{ fontWeight: '400' }}
                          >
                            {answerObj?.answer}
                          </Typography>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
          {smMediaquery ? (
            <Stack
              py={2}
              px={3.5}
              sx={{ boxShadow: '0px -6px 14px 0px rgba(0,0,0,0.06)' }}
            >
              <ButtonReuseable />
            </Stack>
          ) : null}
        </>
      )}
    </Stack>
  );
}
