import React from 'react';
import { Typography } from '@mui/material';

import { useTimer } from './useTimer';
import { secondsToHms } from '../../../../helpers/TimeHelper';

export const Timer = (props) => {
  const {
    stopRecording,
    questionTime,
    setShowAgainVideo,
    setQuestionTime,
    actualQuestionTime,
    status,
  } = props;
  const { getTimer } = useTimer({
    stopRecording,
    questionTime,
    setQuestionTime,
    actualQuestionTime,
    setShowAgainVideo,
    status,
  });

  return (
    getTimer() && (
      <Typography
        variant='title18Bold'
        sx={{
          fontWeight: '700',
          backgroundColor: '#d3113500',
          borderRadius: '40px',
          padding: '6px 20px',
          color: '#fff',
        }}
      >
        {getTimer()}
      </Typography>
    )
  );
};
