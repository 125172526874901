import {useState} from "react";
import {headers, defaultHeaders, usefetch} from "../helpers/fetch";

export const useGetTestStatus = () => {
    const [error, setError] = useState(null);
    const [isTestStatusLoading, setIsTestStatusLoading] = useState(null);
    const [response, setResponse] = useState(null);
    const [videoInterViewInfo, setVideoInterViewInfo] = useState(null);
    const [mcqInfo, setMcqInfo] = useState(null);

    const getTestStatus = async (params) => {
        setIsTestStatusLoading(true);
        const data = {
            testCode: params?.testCode,
        };

        const resp = await usefetch('testBuilder/getTestStatus', 'POST', defaultHeaders(), data);

        if (resp?.code === '11') {
            setVideoInterViewInfo(resp?.data?.videoInterViewInfo);
            setMcqInfo(resp?.data?.mcqInfo);
        }

        if (!resp.ok) {
            setError(resp.error);
        }

        if (resp.ok) {
            setResponse((prevData) => [...prevData, resp]);
        }

        setIsTestStatusLoading(false);
        return resp;
    };

    return { getTestStatus,  response, videoInterViewInfo, mcqInfo, isTestStatusLoading, error};
};