const Style = (theme) => ({
  payment__dialog: {
    zIndex:'1500 !important',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: '100vh',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '0px',
      background:
        'linear-gradient(180deg, rgba(1, 1, 3, 0.87) -34.59%, rgba(0,0,0,0.87) 100%)',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
      backdropFilter: 'blur(4px)',
    },
  },
});
export default Style;
