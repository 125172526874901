import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

const EmojiConfetti = () => {
  const [emojis, setEmojis] = useState([]);

  useEffect(() => {
    const createEmoji = () => {
      const emojiList = ['🎉', '🎈', '🎊', '✨', '💥']; // Add more emojis as needed
      const emoji = emojiList[Math.floor(Math.random() * emojiList.length)];
      const size = Math.random() * 40 + 10; // Random size between 10px and 50px
      return {
        emoji,
        size,
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
      };
    };

    const interval = setInterval(() => {
      setEmojis((prevEmojis) => [
        ...prevEmojis,
        createEmoji(),
      ]);
    }, 1300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden', zIndex:'1500' }}>
      <Confetti width={window.innerWidth} height={window.innerHeight} />
      {emojis.map((emoji, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            left: emoji.x,
            top: emoji.y,
            fontSize: emoji.size,
            animation: 'fall 5s linear infinite',
          }}
        >
          {emoji.emoji}
        </div>
      ))}
    </div>
  );
};

export default EmojiConfetti;
