import React, { useEffect, useState } from 'react';
import TextDictionary from '../../../../common/Dictionary';

export const useCountdown = (params) => {
  const {count} = params;
  const [countdown, setCountdown] = useState(
    count
  );
  const { setStartRecord, setQuestionTime, question, startRecording } = params;

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
        const element = document.getElementById('video__interview__countdown');
        element.innerHTML = countdown - 1;
      }, 1000);
      return () => clearInterval(timer);
    } else {
      handleCountDownFinished();
    }
  }, [countdown]);

  const handleCountDownFinished = () => {
    setStartRecord(false);
    setQuestionTime(question?.time);
    startRecording();
    setCountdown(count);
  };

  const getCountdownTime = () => {
    return countdown;
  };

  return {
    getCountdownTime: getCountdownTime,
  };
};
